import React from 'react';
import { ResponsiveContainer, Scatter, ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { range, partition } from 'lodash/fp';

import { isMainlyDay } from '../data/queries';
import { rawDuration, formatDuration, hourInMs } from '../data/durations';

const SleepDurationsChart = ({ records, ...others }) => {
  const [daySleeps, nightSleeps] = partition(isMainlyDay, records);
  return (
    <ResponsiveContainer {...others} >
      <ScatterChart>
        <CartesianGrid />
        <XAxis
          dataKey="start"
          domain={['dataMin', 'dataMax']}
          type="number"
          name="Start"
          tickFormatter={formatDate}
         />
        <YAxis
          dataKey="duration"
          name="Duration"
          domain={[0, dataMax => Math.ceil(dataMax/hourInMs) * hourInMs]}
          ticks={range(0, 5).map((_,i) => 2*i*hourInMs)}
          type="number"
          tickFormatter={formatDuration}
        />
        <Legend />
        <Scatter data={buildData(nightSleeps)} fill="#88d884" name="Night" shape="circle" />
        <Scatter data={buildData(daySleeps)} fill="#8884d8" name="Day" shape="circle" />
        <Tooltip formatter={formatTooltipValue}/>
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const buildData = (records) =>
  records
    .filter(records => records.data.start && records.data.end)
    .map(toChartRecord);

const toChartRecord = (record) => ({
  start: record.data.start.getTime(),
  end: record.data.end.getTime(),
  duration: rawDuration(record.data),
});

const formatDate = value =>
  new Date(value).toDateString();

const formatTooltipValue = (value, name) => {
  switch(name) {
    case 'Start': return formatDate(value);
    case 'Duration': return formatDuration(value);
    default: return value;
  }
};

export default SleepDurationsChart;
