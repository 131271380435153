import React, { Component } from 'react';

import TopBar from '../components/TopBar';

class UnknownPage extends Component {
  render() {
    return (
        <TopBar>Unknown page</TopBar>
    );
  }
}

export default UnknownPage;
