import React, { Component } from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';

import { withSelectors, records } from '../data/selectors';

import TopBar from '../components/TopBar';
import ExportLink from '../components/ExportLink';
import Importer from '../components/Importer';
import { Paragraph } from '../components/Typography';
import withLoading from '../components/withLoading';

class Data extends Component {

  render() {
    console.log('Data', this.props);
    const { records } = this.props;

    return (
      <React.Fragment>

        <TopBar>Data</TopBar>

        <Paragraph>Download all of the records for the current child to a file:</Paragraph>

        <Paragraph>
          <ExportLink data={records}/>
        </Paragraph>

        <Paragraph>Add new records for the current child from a file:</Paragraph>

        <Paragraph>
          <Importer />
        </Paragraph>

        <Paragraph variant="body2">Note that this will only ADD data to your database.</Paragraph>

      </React.Fragment>
    );
  }
}

export default flow([
  withLoading(props => props.records === null),
  connect(withSelectors({ records })),
])(Data);
