import React, { Component, Fragment } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <Fragment>
          <h1>Error</h1>
          <p>Oh no! It seems the app has crashed:</p>
          <pre><code>{ error.toString() }</code></pre>
          <p>You will probably have to close then restart the app.  Sorry!</p>
        </Fragment>
      );
    }

    return this.props.children;
  }
}
