import { getIntervals } from './dates';
import { duration, getLatestDate, intersects, volume } from './queries';

// Utils

const sum = (a, b) => a + b;

// Occurrences

const latestRecord = (records) => records[0];

const totalCount = (interval) => (records) =>
  records
    .filter(intersects(interval))
    .length;

// Duration

const durations = (interval) => (records) =>
  records
    .filter(intersects(interval)) // NB. Performance
    .map(duration(interval))
    .filter(v => v !== null);

const averageDuration = (interval) => (records) => {
  const figures = durations(interval)(records);
  const count = figures.length;
  const total = figures.reduce(sum, 0);
  return count === 0 ? null : total / count;
};

const totalDuration = (interval) => (records) =>
  durations(interval)(records)
    .reduce(sum, 0);

// Latest time

const latestTime = ({ until }) => (records) => {
  const values = records
    .map(getLatestDate(until))
    .filter(Boolean);

  values.sort((a,b) => b-a);

  return values.length > 0 ? values[0] : null;
};

// Volume

const volumes = (interval) => (records) =>
  records
    .filter(intersects(interval)) // NB. Performance
    .map(volume(interval))
    .filter(v => v !== null);

const totalVolume = (interval) => (records) =>
  volumes(interval)(records)
    .reduce(sum, 0);

const dailyVolumes = ({ since, until }) => (records) =>
  getIntervals(since, until).map(interval => ({
    ...interval,
    volume: totalVolume(interval)(records),
  }))

// Exports

export {
  averageDuration,
  dailyVolumes,
  durations,
  latestRecord,
  latestTime,
  totalCount,
  totalDuration,
  totalVolume,
  volumes,
};
