import { memoize, get } from 'lodash/fp';
import Signal from 'signals';

import DatabasesManager from './DatabasesManager';
import buildDomain from './buildDomain';
import PouchDbSettingsService from './PouchDbSettingsService';

const MASTER_DB_NAME = 'baby-tracker-master'

// Factories (memoized or singleton)
const mgr = DatabasesManager.for(MASTER_DB_NAME);
const appSettings = new PouchDbSettingsService(mgr.masterDb);
const getDb = memoize((db) => mgr.get(db));
const domainFor = memoize((db) => getDb(db).then(buildDomain));

// Async lookups
const managerService     = (  ) => Promise.resolve(mgr);
const appSettingsService = (  ) => Promise.resolve(appSettings);
const domainServiceFor   = (db) => domainFor(db).then(get('domainService'));
const settingsServiceFor = (db) => domainFor(db).then(get('settingsService'));
const syncServiceFor     = (db) => domainFor(db).then(get('sync'));

// Containers
const inManager     = (    fn) => managerService().then(fn);
const inAppSettings = (    fn) => appSettingsService().then(fn);
const inDomain      = (db, fn) => domainServiceFor(db).then(fn);
const inSettings    = (db, fn) => settingsServiceFor(db).then(fn);
const inSync        = (db, fn) => syncServiceFor(db).then(fn);

// Signals
const domainChanged   = new Signal();
const settingsChanged = new Signal();
const syncChanged     = new Signal();
const syncInternal    = new Signal();

// Aggregate domain signals to API signals
const subscribe = (db, serviceLookup, subscribeLookup, signal) => {
  const cb = (...args) => signal.dispatch(db, ...args);
  return serviceLookup(db).then(srv => {
    const subscription = subscribeLookup(srv).add(cb);
    return () => srv.unsubscribe(subscription);
  });
};

const dbUnsubscribers = {};
const subscribeDb = db =>
  Promise.all([
    subscribe(db, domainServiceFor, srv => srv.onChange, domainChanged),
    subscribe(db, settingsServiceFor, srv => srv.onChange, settingsChanged),
    subscribe(db, syncServiceFor, srv => srv.on.changed, syncChanged),
    subscribe(db, syncServiceFor, srv => srv.on.internal, syncInternal),
  ]).then(unsubscribers => {
    // Record unsubscribers in case we ever want to stop listening
    dbUnsubscribers[db] = unsubscribers;
  });
const unsubscribeDb = db => {
  const unsubscribers = dbUnsubscribers[db];
  unsubscribers.forEach(unsubscribe => unsubscribe());
  delete dbUnsubscribers[db];
};

const onDbChange = ({ type, id }) => {
  switch(type) {
    case 'created': return subscribeDb(id);
    case 'deleted': return unsubscribeDb(id);
    default: console.log('Unknown db change type', { type, id });
  }
};
mgr.getAllIds().then(dbs => dbs.map(subscribeDb));
mgr.subscribe(onDbChange);

// API
const api = {
  commands: {
    createDatabase:    (    ...args) => inManager(mgr => mgr.create(...args)),
    deleteDatabase:    (    ...args) => inManager(mgr => mgr.delete(...args)),
    updateAppSettings: (    ...args) => inAppSettings(srv => srv.updateSettings(...args)),
    startSync:         (db, ...args) => inSync(db, srv => srv.start(...args)),
    addRecord:         (db, ...args) => inDomain(db, srv => srv.addRecord(...args)),
    addRecords:        (db, ...args) => inDomain(db, srv => srv.addRecords(...args)),
    updateRecord:      (db, ...args) => inDomain(db, srv => srv.updateRecord(...args)),
    deleteRecord:      (db, ...args) => inDomain(db, srv => srv.deleteRecord(...args)),
    updateSettings:    (db, ...args) => inSettings(db, srv => srv.updateSettings(...args)),
  },
  queries: {
    getAllIds:      (    ...args) => inManager(mgr => mgr.getAllIds(...args)),
    getAppSettings: (    ...args) => inAppSettings(srv => srv.getSettings(...args)),
    getRecords:     (db, ...args) => inDomain(db, srv => srv.getRecords(...args)),
    getTopics:      (db, ...args) => inDomain(db, srv => srv.getTopics(...args)),
    getSettings:    (db, ...args) => inSettings(db, srv => srv.getSettings(...args)),
    getSyncStatus:  (db, ...args) => inSync(db, srv => srv.getStatus(...args)),
  },
  signals: {
    domainChanged,
    settingsChanged,
    syncChanged,
    syncInternal,
  },
  diagnostics: {
    getDatabase: getDb,
  },
};

export default api;
