import parseDateFn from 'date-fns/parse';
import formatDateFn from 'date-fns/format';
import { flow, filter, orderBy, identity, first } from 'lodash/fp';

export const toFormat = (format) => (date) =>
  formatDateFn(date, format);

export const parse = (value) =>
  parseDateFn(value);

export const hourInMs = 1000 * 60 * 60;
export const dayInMs = hourInMs * 24;
export const weekInMs = dayInMs * 7;

export const findEarliest = flow([
  filter(identity),
  orderBy(identity, 'asc'),
  first,
]);

export const findLatest = flow([
  filter(identity),
  orderBy(identity, 'desc'),
  first,
]);

export const startOf = (day) => {
  const val = new Date(day);
  val.setHours(0);
  val.setMinutes(0);
  val.setSeconds(0);
  val.setMilliseconds(0);
  return val;
};

export const endOf = (day) => {
  const val = new Date(day);
  val.setHours(23);
  val.setMinutes(59);
  val.setSeconds(59);
  val.setMilliseconds(999);
  return val;
};

export const getDaysBefore = (moment, days) => {
  const ms = moment.getTime() - (days * dayInMs);
  return new Date(ms);
};

export const getDayBefore = (moment) => {
  const ms = moment.getTime() - dayInMs;
  return new Date(ms);
};

export const getDayAfter = (moment) => {
  const ms = moment.getTime() + dayInMs;
  return new Date(ms);
};

export const getDatesInRange = (since, until, opts={}) => {
  const dates = [];

  let day = since;
  do {
    dates.push(day);
    day = getDayAfter(day);
  } while (day <= until)

  if (opts.latestFirst) {
    dates.reverse();
  }

  return dates;
};

export const getIntervals = (since, until) => {
  const intervals = [];

  let end = until;
  do {
    const start = getDayBefore(end);
    intervals.push({ since: start, until: end });
    end = start;
  } while (end > since)

  return intervals;
};
