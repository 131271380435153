import React from 'react';

import TimeAgo from 'timeago-react';

import Duration from './Duration';

/**
 * Render text representing the gap between two events.
 */
const EventGap = ({ since, until }) => {
  const sinceDate = since ? findEndDate(since) : null;
  const untilDate = until ? findStartDate(until) : null;

  if (!sinceDate) return null;

  return untilDate ?
    <Duration start={sinceDate} end={untilDate} /> :
    <TimeAgo datetime={sinceDate} />;
};

const findEndDate = ({ data }) => {
  if (data.end) return data.end;
  if (data.start) return data.start;
  return null;
};

const findStartDate = ({ data }) => {
  if (data.start) return data.start;
  return null;
};

export default EventGap;
