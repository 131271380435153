import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { range, pipe, keys, map, filter, groupBy, sum, max, min } from 'lodash/fp';

import { rawDuration, formatDuration, hourInMs } from '../data/durations';
import { startOf } from '../data/dates';

const SleepTrendsChart = ({ records, ...others }) => (
  <ResponsiveContainer {...others} >
    <LineChart data={buildData(records)}>
      <XAxis
        dataKey="day"
        domain={['dataMin', 'dataMax']}
        type="number"
        name="Day"
        tickFormatter={formatDate}
      />
      <YAxis
        type="number"
        domain={[0, dataMax => Math.ceil(dataMax/hourInMs) * hourInMs]}
        ticks={range(0, 5).map((_,i) => 2*i*hourInMs)}
        name="Duration"
        tickFormatter={formatDuration}
      />
      <CartesianGrid />
      <Legend />
      <Line type="monotoneX" dataKey="min" name="Min" stroke="#88d884" />
      <Line type="monotoneX" dataKey="mean" name="Mean" stroke="#8884d8" />
      <Line type="monotoneX" dataKey="max" name="Max" stroke="#d88488" />
      <Tooltip formatter={formatTooltipValue} labelFormatter={formatDateLabel}/>
    </LineChart>
  </ResponsiveContainer>
);

const buildData = pipe([
  filter(records => records.data.start && records.data.end),
  map(sleep => ({
    day: startOf(sleep.data.start).getTime(),
    duration: rawDuration(sleep.data),
  })),
  groupBy(({day, duration}) => day),
  (grouped) => keys(grouped).reduce((acc, day) => {
    const durations = grouped[day].map(d => d.duration);
    const measures = {
      day,
      min: min(durations),
      mean: sum(durations) / durations.length,
      max: max(durations),
    };
    return acc.concat(measures);
  }, []),
]);

const formatTooltipValue = (value, name) =>
  formatDuration(value);

const formatDate = value =>
  new Date(value).toDateString();

const formatDateLabel = pipe([parseInt, formatDate]);

export default SleepTrendsChart;
