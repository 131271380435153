import { createReducer } from 'redux-act';

import { recordSelected, recordDeselected } from './actions';

const init = null;

export default createReducer({
  [recordSelected]: (_state, record) => record,
  [recordDeselected]: (_state) => init,
}, init);
