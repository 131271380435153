import React, { Component } from 'react';
import { flow, isEqual } from 'lodash/fp';
import { connect } from 'react-redux';

import { updateSettings, deleteCurrentDatabase } from '../data/redux/thunks';
import { withSelectors, settings, topics } from '../data/selectors';

import TopBar from '../components/TopBar';
import SettingsForm from '../components/SettingsForm';
import withLoading from '../components/withLoading';

class Settings extends Component {

  state = { settings: this.props.settings }

  onSettingChanged = (setting, value) => {
    const update = { [setting]: value };
    this.setState(({ settings }) => ({
      settings: { ...settings, ...update },
    }));
  }

  onSave = () => {
    this.props.updateSettings(this.state.settings);
  }

  onCancel = () => {
    this.setState({ settings: this.props.settings });
  }

  onDelete = () => {
    this.props.deleteCurrentDatabase();
  }

  render() {
    console.log('Settings', this.props, this.state);
    const { topics } = this.props;
    const { settings } = this.state;

    const enableSave = !isEqual(this.props.settings, settings);

    return (
      <React.Fragment>

        <TopBar>Settings</TopBar>

        <SettingsForm
          settings={settings}
          topics={topics}
          onSettingChanged={this.onSettingChanged}
          onSave={this.onSave}
          onDelete={this.onDelete}
          onCancel={this.onCancel}
          enableSave={enableSave}
        />

      </React.Fragment>
    );
  }

}

export default flow([
  withLoading(props => !props.settings),
  connect(withSelectors({ settings, topics }), { updateSettings, deleteCurrentDatabase }),
])(Settings);
