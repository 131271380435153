import { isType, dataPropIs } from './queries';

const isFeedSide = dataPropIs('side');
const isNappyType = dataPropIs('type');
const isBottleType = dataPropIs('type');
const isTherapyType = dataPropIs('type');

export const breastFeeds = {
  name: 'Breast feeds',
  id: 'breast-feed',
  icon: { topicId: 'breast-feed' },
  config: { latestTime: true, totalCount: true, totalDuration: true },
  subsets: [
    { name: 'All',   filter: r => isType('breast-feed')(r) },
    { name: 'Left',  filter: r => isType('breast-feed')(r) && isFeedSide('Left')(r)  },
    { name: 'Right', filter: r => isType('breast-feed')(r) && isFeedSide('Right')(r) },
  ],
};

export const bottleFeeds = {
  name: 'Bottle feeds',
  id: 'bottle-feed',
  icon: { topicId: 'bottle-feed' },
  config: { latestTime: true, totalCount: true, totalDuration: true, totalVolume: true, dailyVolumes: true },
  subsets: [
    { name: 'All',         filter: r => isType('bottle-feed')(r) },
    { name: 'Breast milk', filter: r => isType('bottle-feed')(r) && isBottleType('Breast milk')(r) },
    { name: 'Formula',     filter: r => isType('bottle-feed')(r) && isBottleType('Formula')(r)     },
  ],
};

export const nappyChanges = {
  name: 'Nappy change',
  id: 'nappy-change',
  icon: { topicId: 'nappy-change' },
  config: { latestTime: true, totalCount: true },
  subsets: [
    { name: 'All', filter: isType('nappy-change') },
    { name: 'Wet/Mixed',   filter: r => isType('nappy-change')(r) && (isNappyType('Wet')(r)   || isNappyType('Mixed')(r)) },
    { name: 'Dirty/Mixed', filter: r => isType('nappy-change')(r) && (isNappyType('Dirty')(r) || isNappyType('Mixed')(r)) },
  ],
};

export const sleep = {
  name: 'Sleep',
  id: 'sleep',
  icon: { topicId: 'sleep' },
  config: { latestTime: true, totalCount: true, totalDuration: true },
  subsets: [
    { name: 'All', filter: isType('sleep') },
  ],
};

export const therapySessions = {
  name: 'Therapy sessions',
  id: 'therapy-sessions',
  icon: { topicId: 'therapy-session' },
  config: { latestTime: true, totalCount: true },
  subsets: [
    { name: 'All', filter: isType('therapy-session') },
    { name: 'Physiotherapy', filter: r => isType('therapy-session')(r) && isTherapyType('Physiotherapy')(r) },
  ],
};

export default [
  breastFeeds,
  bottleFeeds,
  nappyChanges,
  sleep,
  therapySessions,
];
