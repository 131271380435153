import React, { Fragment } from 'react';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';

import TopicIcon from '../TopicIcon';
import { Paragraph } from '../Typography';

import Time from '../Time';
import Duration from '../Duration';
import { toFormat } from '../../data/dates';

const getCalendarDateString = toFormat('YYYY-MM-DD');

const isSameCalendarDay = (target) => (value) =>
  getCalendarDateString(target) === getCalendarDateString(value);

export const TimeInfo = ({ start, end }) => {
  if (!start) return null;

  const isToday = isSameCalendarDay(new Date())(start);
  const format = isToday ? 'HH:mm' : 'Do MMM HH:mm';

  return end ? (
      <Fragment>
        <Time datetime={start} format={format} /> (<Duration start={start} end={end} />)
      </Fragment>
    ) : (
      <Time datetime={start} format={format} />
    );
};

export const TimeRangeInfo = ({ start, end }) => {
  if (!start) return null;

  const isToday = isSameCalendarDay(new Date())(start);
  const format = isToday ? 'HH:mm' : 'Do MMM HH:mm';

  return end ? (
      <Fragment>
        <Time datetime={start} format={format} /> - <Time datetime={end} format={format} />
      </Fragment>
    ) : (
      <Time datetime={start} format={format} />
    );
};

export const Notes = ({ notes }) => {
  return notes ? (
    <span style={{ whiteSpace: 'pre-line' }}>{ notes }</span>
  ) : null;
};

const styles = theme => ({
  pulse: {
    animation: '$highlightPulse 0.6s linear infinite alternate',
    fontWeight: 500,
  },
  '@keyframes highlightPulse': {
    from: {
      opacity: 1.0,
      color: 'inherit',
    },
    to: {
      opacity: 0.4,
      color: theme.palette.primary.main,
    },
  },

  // HACK: When "active" we show the secondary action and the default styling
  // allows the primary and secondary text to overlap it.  Manually work around
  // by adjusting padding and position of the text and action respectively.
  listItemSecondaryActionActive: {
    // Move secondary action closer to RHS
    right: theme.spacing(1), // Default is 2, looks more consistent with 1
  },
  listItemActive: {
    // Make space for secondary action (otherwise text overlaps action)
    paddingRight: theme.spacing(6 + 1), // 6 for secondary action + 1 for action RHS
  },
});

const BaseRecordItem = ({ classes, topic, blurb, active, onStop, ...rest }) => {
  const primary = (
    <Paragraph paragraph={false} className={classnames({[classes.pulse]: active})}>
      {topic.name}
    </Paragraph>
  );

  const stopButton = (
    <ListItemSecondaryAction className={classnames({[classes.listItemSecondaryActionActive]: active})}>
      <IconButton color="secondary" onClick={onStop}>
        <StopIcon />
      </IconButton>
    </ListItemSecondaryAction>
  );

  return (
    <ListItem {...rest} className={classnames({[classes.listItemActive]: active})}>
      <ListItemIcon>
        <TopicIcon topicId={topic.id} />
      </ListItemIcon>
      <ListItemText primary={primary} secondary={blurb} />
      { active && stopButton }
    </ListItem>
  );
};

export default withStyles(styles)(BaseRecordItem);
