import { getOr } from 'lodash/fp';

const msgOrSelf = error => getOr(error, 'message', error);

export const notImplementedError = () => ({
  type: 'BabyTracker',
  code: 'DeveloperError',
  message: 'Not implemented',
});

export const abortedError = () => ({
  type: 'BabyTracker',
  code: 'ParsingAborted',
  message: 'Parsing of the file was aborted',
});

export const readingError = error => ({
  type: 'BabyTracker',
  code: 'ReadingFileFailed',
  message: msgOrSelf(error),
  error,
});

export const parsingError = error => ({
  type: 'BabyTracker',
  code: 'ParsingError',
  message: msgOrSelf(error),
  error,
});

export const argumentError = error => ({
  type: 'BabyTracker',
  code: 'ArgumentError',
  message: msgOrSelf(error),
  error,
});

export const recordError = (rowIndex, error) => ({
  type: 'BabyTracker',
  code: 'RecordError',
  message: msgOrSelf(error),
  error,
  rowIndex,
});

export const importError = (error) => ({
  type: 'BabyTracker',
  code: 'ImportError',
  message: msgOrSelf(error),
  error,
});
