import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { pipe, filter, map } from 'lodash/fp';

const MeasurementsChart = ({ records, ...others }) => (
  <ResponsiveContainer {...others} >
    <LineChart>
      <XAxis
        name='Date'
        type='number'
        dataKey='start'
        domain={['dataMin', 'dataMax']}
        tickFormatter={formatDate}
      />
      <YAxis
        name='Length'
        type='number'
        dataKey='value'
        domain={['dataMin', 'dataMax']}
        yAxisId={0}
        orientation='left'
      />
      <YAxis
        name='Weight'
        type='number'
        dataKey='value'
        domain={['dataMin', 'dataMax']}
        yAxisId={1}
        orientation='right'
      />
      <Line
        key='line0'
        name='Length (m)'
        dataKey='value'
        yAxisId={0}
        stroke='#88d884'
        data={buildData('Length')(records)}
        type='monotoneX'
      />
      <Line
        key='line1'
        name='Weight (kg)'
        dataKey='value'
        yAxisId={1}
        stroke='#8884d8'
        data={buildData('Mass')(records)}
        type='monotoneX'
      />
      <CartesianGrid />
      <Legend />
      <Tooltip labelFormatter={labelFormatter}/>
    </LineChart>
  </ResponsiveContainer>
);

const buildData = (type) => pipe([
  filter(record => record.data),
  filter(record => record.data.start),
  filter(record => record.data.type === type),
  map(({ data }) => ({
    start: data.start.getTime(),
    value: parseFloat(data.quantity),
  })),
]);

const formatDate = start =>
  new Date(start).toDateString();

const labelFormatter = start =>
  new Date(start).toLocaleString('en-GB');

export default MeasurementsChart;
