import { readingError, parsingError } from './errors';

const parseJson = (file) =>
  readFile(file).then(jsonToRows);

const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      reader.error
        ? reject([readingError(reader.error)])
        : resolve(reader.result);
    reader.readAsText(file);
  });

const jsonToRows = (json) =>
  new Promise((resolve, reject) => {
    try {
      const result = JSON.parse(json);
      resolve(result);
    } catch(error) {
      reject([parsingError(error)]);
    }
  });

export default parseJson;
