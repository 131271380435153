import React, { Component, Fragment } from 'react';
import { flow, get, isNumber } from 'lodash/fp';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Duration from '../components/Duration';
import TighterCardContent from '../components/TighterCardContent';
import { Paragraph } from '../components/Typography';

import reports from '../data/reports';
import { records } from '../data/selectors';

import FormattedNumber from '../components/FormattedNumber';
import ReportProvider from '../components/ReportProvider';
import SpacedCard from '../components/SpacedCard';
import TopBar from '../components/TopBar';
import TopicIcon from '../components/TopicIcon';
import withLoading from '../components/withLoading';
import VolumesBarChart from '../components/VolumesBarChart';

class Report extends Component {

  render() {
    console.log('Report', this.props);

    const { report, records } = this.props;

    return (
      <Fragment>

        <TopBar showBack icon={<TopicIcon topicId={report.icon.topicId}/>}>{ report.name }</TopBar>

        <ReportProvider report={report} records={records}>
          <ReportDetails />
        </ReportProvider>

      </Fragment>
    );
  }
}

const ReportDetails = ({ report }) => (
  <Fragment>
    <LatestTime report={report} />
    { hasTotalCount(report) && <TotalCount report={report} /> }
    { hasTotalDuration(report) && <TotalDuration report={report} /> }
    { hasTotalVolume(report) && <TotalVolume report={report} /> }
    { hasDailyVolumes(report) && <DailyVolumes report={report} /> }
    { hasDailyVolumes(report) && <DailyVolumesChart report={report} /> }
  </Fragment>
);

const hasTotalCount = (report) =>
  report.subsets.every(subset => subset.results.totalCount);

const hasTotalDuration = (report) =>
  report.subsets.every(subset => subset.results.totalDuration);

const hasTotalVolume = (report) =>
  report.subsets.every(subset => subset.results.totalVolume);

const hasDailyVolumes = (report) =>
  report.subsets.every(subset => subset.results.dailyVolumes);

const LatestTime = ({ report }) => (
  <SpacedCard>
    <TighterCardContent>
      <Paragraph>Last occurrence</Paragraph>
      <Paragraph variant="body2" color="textSecondary">The last time this happened:</Paragraph>
      <Table padding="none">
        <TableBody>
        {
          report.subsets
            .map(subset => ({
              name: subset.name,
              latestTime: subset.results.latestTime,
            }))
            .map(subset => (
              <TableRow key={subset.name}>
                <TableCell component="th" scope="row">{subset.name}</TableCell>
                <TableCell align="right">
                  <Duration start={subset.latestTime} end={new Date()} />
                </TableCell>
              </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </TighterCardContent>
  </SpacedCard>
);

const averageStyle = { opacity: 0.5 };

const TotalCount = ({ report }) => (
  <SpacedCard>
    <TighterCardContent>
      <Paragraph>Daily count</Paragraph>
      <Paragraph variant="body2" color="textSecondary">Total count over the last 24 hours (compared to the daily average for the preceding 10 days):</Paragraph>
      <Table padding="none">
        <TableBody>
        {
          report.subsets
            .map(subset => ({
              name: subset.name,
              today: subset.results.totalCount.today,
              average: subset.results.totalCount.average,
            }))
            .map(subset => (
              <TableRow key={subset.name}>
                <TableCell component="th" scope="row">{subset.name}</TableCell>
                <TableCell align="right">
                  <FormattedNumber value={subset.today} format="0.[0]" />
                </TableCell>
                <TableCell align="right" style={averageStyle}>
                  <MetricChange value={change(subset)} />
                </TableCell>
                <TableCell align="right" style={averageStyle}>
                  <FormattedNumber value={subset.average} format="0.[0]" />
                </TableCell>
              </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </TighterCardContent>
  </SpacedCard>
);

const TotalDuration = ({ report }) => (
  <SpacedCard>
    <TighterCardContent>
      <Paragraph>Daily duration</Paragraph>
      <Paragraph variant="body2" color="textSecondary">Total duration over the last 24 hours (compared to the daily average for the preceding 10 days):</Paragraph>
      <Table padding="none">
        <TableBody>
        {
          report.subsets
            .map(subset => ({
              name: subset.name,
              today: subset.results.totalDuration.today,
              average: subset.results.totalDuration.average,
            }))
            .map(subset => (
              <TableRow key={subset.name}>
                <TableCell component="th" scope="row">{subset.name}</TableCell>
                <TableCell align="right">
                  <Duration duration={subset.today} />
                </TableCell>
                <TableCell align="right" style={averageStyle}>
                  <MetricChange value={change(subset)} />
                </TableCell>
                <TableCell align="right" style={averageStyle}>
                  <Duration duration={subset.average} />
                </TableCell>
              </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </TighterCardContent>
  </SpacedCard>
);

const change = ({ today, average }) =>
  average === 0 ?
    '-' : 
    today / average;

const MetricChange = ({ value }) => isNumber(value) ? (
  <FormattedNumber value={value} format="0%" />
) : value;

const TotalVolume = ({ report }) => (
  <SpacedCard>
    <TighterCardContent>
      <Paragraph>Daily volume</Paragraph>
      <Paragraph variant="body2" color="textSecondary">Total over the last 24 hours (compared to the daily average for the preceding 10 days):</Paragraph>
      <Table padding="none">
        <TableBody>
        {
          report.subsets
            .map(subset => ({
              name: subset.name,
              today: subset.results.totalVolume.today,
              average: subset.results.totalVolume.average,
            }))
            .map(subset => (
              <TableRow key={subset.name}>
                <TableCell component="th" scope="row">{subset.name}</TableCell>
                <TableCell align="right">
                  <FormattedNumber value={subset.today} format="0.[0]" />
                  { ' ml' }
                </TableCell>
                <TableCell align="right" style={averageStyle}>
                  <MetricChange value={change(subset)} />
                </TableCell>
                <TableCell align="right" style={averageStyle}>
                  <FormattedNumber value={subset.average} format="0.[0]" />
                  { ' ml' }
                </TableCell>
              </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </TighterCardContent>
  </SpacedCard>
);

const DailyVolumes = ({ report }) => (
  <SpacedCard>
    <TighterCardContent>
      <Paragraph>
        <span role="img" aria-label="beta"> 🚧 </span>
        Daily volumes
        <span role="img" aria-label="beta"> 🚧 </span>
      </Paragraph>
      <Paragraph variant="body2" color="textSecondary">Total over each 24 hour period going back 10 days:</Paragraph>
      <Table padding="none">
        <TableBody>
        {
          report.subsets
            .map(subset => ({
              name: subset.name,
              dailyVolumes: subset.results.dailyVolumes,
            }))
            .map(subset => (
              <TableRow key={subset.name}>
                <TableCell component="th" scope="row">{subset.name}</TableCell>
                {
                  subset.dailyVolumes.map(({ until, volume }, i) => {
                    const isLatest = i === 0;
                    const style = isLatest ? null : averageStyle;
                    const suffix = isLatest ? ' ml' : null;
                    return (
                      <TableCell key={until} align="right" style={style}>
                        <FormattedNumber value={volume} format="0.[0]" />
                        { suffix }
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            ))
        }
        </TableBody>
      </Table>
    </TighterCardContent>
  </SpacedCard>
);

const DailyVolumesChart = ({ report }) => (
  <SpacedCard>
    <TighterCardContent>
      <Paragraph>
        <span role="img" aria-label="beta"> 🚧 </span>
        Daily volumes
        <span role="img" aria-label="beta"> 🚧 </span>
      </Paragraph>
      <Paragraph variant="body2" color="textSecondary">Total over each 24 hour period going back 10 days:</Paragraph>
      <VolumesBarChart
        data={report.subsets[0].results.dailyVolumes}
        width="100%"
        height={300}
      />
    </TighterCardContent>
  </SpacedCard>
);

export default flow([
  withLoading(props => props.records === null),
  connect((state, props) => {
    const reportId = get('match.params.reportId', props);
    const report = reports.find(r => r.id === reportId);
    return { records: records(state), report };
  }),
])(Report);
