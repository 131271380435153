import { createReducer } from 'redux-act';

import { databasesLoaded, syncChanged } from './actions';

export const disconnectedState = { status: 'disconnected', error: null };

const initialiseDb = (state, { id }) => ({ ...state, [id]: disconnectedState });

export default createReducer({
  [databasesLoaded]: (state, dbs) => dbs.reduce(initialiseDb, state),
  [syncChanged]: (state, { db, syncStatus }) => ({ ...state, [db]: syncStatus }),
}, {});
