import React, { Component }  from 'react';

import logger from '../data/logger';
import getDisplayName from './getDisplayName';

// HOC that adds the log messages in a given prop
const withLogMessages = (prop) => (WrappedComponent) => {
  class WithLogMessages extends Component {
    state = { messages: logger.getMessages() }

    logChanged = (messages) => {
      this.setState({ messages });
    };

    componentDidMount() {
      this.unsubscribe = logger.subscribe(this.logChanged);
    }

    componentWillUnmount() {
      this.unsubscribe && this.unsubscribe();
    }

    render() {
      const { messages } = this.state;
      return <WrappedComponent {...this.props} {...{[prop]: messages}} />;
    }
  }

  WithLogMessages.displayName = `WithLogMessages(${getDisplayName(WrappedComponent)})`;

  return WithLogMessages;
}

export default withLogMessages;
