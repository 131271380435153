import React, { Fragment } from 'react';

import EditForm from './_EditForm';
import { FancyDateTime, Select, TextArea } from '../Forms'

class EditForm_Contraction extends EditForm {

  constructor(props, context) {
    super(props, context, 'contraction');
  }

  state = {
    start: EditForm.getField('data.start')(this.props.record),
    end: EditForm.getField('data.end')(this.props.record),
    discomfort: EditForm.getField('data.discomfort')(this.props.record),
    notes: EditForm.getField('data.notes')(this.props.record),
  }

  buildRecordData = () => {
    const { start, end, discomfort, notes } = this.state;

    return {
      start: start || null,
      end: end || null,
      discomfort,
      notes,
    };
  }

  renderTopicFields = () => {
    const { start, end, discomfort, notes } = this.state;

    return (
      <Fragment>

        <FancyDateTime
          label="Start"
          margin="normal"
          value={start}
          onChange={this.onChange('start')}
        />

        <FancyDateTime
          label="End"
          margin="normal"
          value={end}
          onChange={this.onChange('end')}
        />

        <Select
          label="Discomfort"
          margin="normal"
          value={discomfort || ''}
          onChange={this.onDomChange('discomfort')}
        >
          <option value="">--Select--</option>
          <option>Minimal</option>
          <option>Some</option>
          <option>Unpleasant</option>
          <option>Painful</option>
          <option>Very painful</option>
        </Select>

        <TextArea
          label="Notes"
          margin="normal"
          rowsMax={8}
          value={notes || ''}
          onChange={this.onDomChange('notes')}
        />

      </Fragment>
    );
  }

}

export default EditForm_Contraction;
