import { connect } from 'react-redux';

import { withSelectors, currentSyncStatus } from '../data/selectors';

/**
 * A component that displays the sync status.
 */
export const DatabaseSyncMessage = ({ currentSyncStatus }) =>
  currentSyncStatus ?
    (messageBuilder[currentSyncStatus.status] || unknownBuilder)(currentSyncStatus) :
    '-- No sync status --';

const messageBuilder = {
  'connecting': () => '☁️ Connecting...',
  'connected': () => '✅ Synchronised and watching for changes',
  'active': () => '⏳ Synchronising with remote...',
  'disconnected': ({error}) => error ? `❌ Local only. ${error.message || 'Unknown error'}` : '📱 Local only (configure a sync server to share data)',
};

const unknownBuilder = () => 'Unknown status';

const mapStateToProps = withSelectors({ currentSyncStatus });

export default connect(mapStateToProps)(DatabaseSyncMessage);
