import React, { Component } from 'react';

import SyncStatusButton from '../components/SyncStatusButton';
import SyncStatusDialog from '../components/SyncStatusDialog';

class SyncDialogManager extends Component {
  state = {
    isDialogOpen: false,
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  }

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  }

  render() {
    const { isDialogOpen } = this.state;

    return (
      <React.Fragment>
        <SyncStatusButton onClick={this.openDialog} />
        <SyncStatusDialog onClose={this.closeDialog} open={isDialogOpen} />
      </React.Fragment>
    );
  }
}

export default SyncDialogManager;
