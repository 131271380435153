import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const withLoading = (propsPredicate) => (WrappedComponent) => (props) =>
    propsPredicate(props)
      ? (
        <CenteredContainer>
          <CircularProgress />
        </CenteredContainer>
      ) : (
        <WrappedComponent { ...props } />
      )

const CenteredContainer = (props) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',
    }}
    {...props}
  />
);

export default withLoading;
