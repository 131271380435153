import React, { Fragment } from 'react';

import EditForm from './_EditForm';
import { FancyDateTime, TextArea } from '../Forms'

class EditForm_Moment extends EditForm {

  state = {
    start: EditForm.getField('data.start')(this.props.record),
    notes: EditForm.getField('data.notes')(this.props.record),
  }

  buildRecordData = () => {
    const { start, notes } = this.state;

    return {
      start: start || null,
      notes,
    };
  }

  renderTopicFields = () => {
    const { start, notes } = this.state;

    return (
      <Fragment>

        <FancyDateTime
          label="At"
          margin="normal"
          value={start}
          onChange={this.onChange('start')}
        />

        <TextArea
          label="Notes"
          margin="normal"
          rowsMax={8}
          value={notes || ''}
          onChange={this.onDomChange('notes')}
        />

      </Fragment>
    );
  }

}

export default EditForm_Moment;
