import { flow } from 'lodash';

// This is a seam to allow for testing.  When dispatching a thunk
// from inside a thunk, find the second one from this collection so
// it can be mocked in tests.
import * as thunks from './thunks.resolver';
import * as actions from './actions';

import { gatherArgs } from  './utilities';
import { generateId } from './ids';

// Utilities

// Wrap fn with actions
const around = (fn, actions, dispatch) => {
  const noop = () => {};
  const { toStart=noop, toSuccess=noop, toError=noop } = actions;
  const dispatchIfAction = (action) => action && dispatch(action);

  return Promise.resolve(toStart())
    .then(dispatchIfAction)
    .then(fn)
    .then(toSuccess, toError)
    .then(dispatchIfAction);
};

const isCurrentDb = (db, getState) => {
  const { selectedDatabaseId } = getState();
  return db === selectedDatabaseId;
};

const createBlankRecord = (topic) => ({ type: topic.id });

// CRUD

export const loadAllDatabases = () => (dispatch, _getState, { queries }) => {
  const getDatabasesInfo = () =>
    queries.getAllIds()
      .then(ids => Promise.all(ids.map(getDatabaseInfo)))

  const getDatabaseInfo = (id) =>
    queries.getSettings(id)
      .then(({ displayName }) => ({ id, displayName: displayName || 'Baby Tracker' }));

  return around(getDatabasesInfo, {
    toSuccess: actions.databasesLoaded,
  }, dispatch);
};

export const deleteCurrentDatabase = () => async (dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();
  await commands.deleteDatabase(selectedDatabaseId);
  await dispatch(thunks.reloadApp());
};

export const addRecord = (record) => (_dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();
  return commands.addRecord(selectedDatabaseId, record);
};

export const addRecords = (records) => (_dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();
  return commands.addRecords(selectedDatabaseId, records);
};

export const updateRecord = (record) => (_dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();
  return commands.updateRecord(selectedDatabaseId, record);
};

export const deleteRecord = (record) => (_dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();
  return commands.deleteRecord(selectedDatabaseId, record);
};

export const quickStart = (topic, data={}) => (_dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();

  const record = {
    type: topic.id,
    data: { ...data, start: new Date() },
    meta: { timestamp: Date.now() },
  };

  return commands.addRecord(selectedDatabaseId, record);
};

export const stopRecord = (record) => (dispatch) => {
  const hasStart = !!record.data.start;
  const hasEnd = !!record.data.end;

  if (hasStart && !hasEnd) {
    const updatedData = { ...record.data, end: new Date() };
    const updatedRecord = { ...record, data: updatedData };

    dispatch(thunks.updateRecord(updatedRecord));
  }
};

export const updateSettings = (settings) => (_dispatch, getState, { commands }) => {
  const { selectedDatabaseId } = getState();
  return commands.updateSettings(selectedDatabaseId, settings);
};

export const loadAllRecords = () => (dispatch, getState, { queries }) => {
  const { selectedDatabaseId } = getState();
  return around(() => queries.getRecords(selectedDatabaseId), {
    toStart: actions.recordsStarted,
    toSuccess: actions.recordsLoaded,
  }, dispatch);
};

export const loadAllTopics = () => (dispatch, getState, { queries }) => {
  const { selectedDatabaseId } = getState();
  return around(() => queries.getTopics(selectedDatabaseId), {
    toStart: actions.topicsStarted,
    toSuccess: actions.topicsLoaded,
  }, dispatch);
};

export const loadAllSettings = () => (dispatch, getState, { queries }) => {
  const { selectedDatabaseId } = getState();
  return around(() => queries.getSettings(selectedDatabaseId), {
    toSuccess: actions.settingsUpdated,
  }, dispatch);
};

export const logMessage = (...args) => (_dispatch, _getState, { logger }) => {
  console.log(...args);
  logger.log(...args);
};

// NAVIGATION

export const viewLog = () => (_dispatch, _getState, { history }) => {
  history.push('/log');
};

export const viewDiagnostics = () => (_dispatch, _getState, { history }) => {
  history.push('/diagnostics');
};

export const viewChangeLog = () => (_dispatch, _getState, { history }) => {
  history.push('/changelog');
};

export const viewRecords = () => (_dispatch, _getState, { history }) => {
  history.push('/');
};

export const viewReport = (report) => (_dispatch, _getState, { history }) => {
  history.push(`/reports/${report.id}`);
};

export const addTopic = (topic) => (dispatch, _getState, { history }) => {
  const record = createBlankRecord(topic);
  dispatch(actions.recordSelected(record));
};

export const editRecord = (record) => (dispatch, _getState, { history }) => {
  dispatch(actions.recordSelected(record));
};

export const finishEditing = () => (dispatch, _getState, { history }) => {
  dispatch(actions.recordDeselected());
};

// APPLICATION

export const reloadApp = () => () => {
  // HACK: Reload app
  window.location.href = '/';
};

export const init = () => async (dispatch, getState, { commands, signals, queries }={}) => {
  trackData(dispatch, signals);
  addLogging(dispatch, signals);

  await dispatch(thunks.loadAllDatabases());

  // Try to autoselect a database
  let databases = getState().databases;

  // Create default db if none available
  if (databases.length === 0) {
    await dispatch(thunks.logMessage('No databases found, creating default'));
    await commands.createDatabase(generateId());
    await dispatch(thunks.loadAllDatabases());
    databases = getState().databases;
  } else {
    await dispatch(thunks.logMessage('Databases found:', databases));
  }

  // Autoselect db
  const { selectedDatabaseId } = await queries.getAppSettings();
  const previouslySelectedDb = selectedDatabaseId
    ? databases.find(db => db.id === selectedDatabaseId)
    : null;
  const firstDb = (databases.length > 0) ? databases[0] : null;
  const dbToSelect = previouslySelectedDb || firstDb;
  if (dbToSelect) {
    await dispatch(thunks.selectDatabase(dbToSelect.id));
  } else {
    await dispatch(thunks.logMessage('ERROR: Could not find a db to auto select'));
  }

  await dispatch(thunks.startAllDbSync());
};

const trackData = (dispatch, signals) => {
  const collectByDb = (byDb, [db, change]) => ({
    ...byDb,
    [db]: byDb[db] ? [...byDb[db], change] : [change],
  });
  const batchedByDb = gatherArgs(500, collectByDb, {});

  // NOTE: Throttle domain events (lots of them when syncing)
  signals.domainChanged.add(batchedByDb(flow(thunks.domainChanged, dispatch)));
  signals.settingsChanged.add(flow(thunks.settingsChanged, dispatch));
  signals.syncChanged.add(flow(thunks.syncChanged, dispatch));
};

const addLogging = (dispatch, signals) => {
  const log = (...moduleArgs) => (...args) =>
    dispatch(thunks.logMessage(...moduleArgs, ...args));

  signals.domainChanged.add((db, _change) => log('domainChanged')(db));
  signals.settingsChanged.add(log('settingsChanged'));
  signals.syncChanged.add(log('syncChanged'));
  signals.syncInternal.add(log('syncInternal'));
};

export const domainChanged = (changesByDb) => (dispatch, getState) => {
  const { selectedDatabaseId } = getState();
  const changes = changesByDb[selectedDatabaseId] || [];

  return dispatch(actions.recordsChanged(changes));
};

export const settingsChanged = (db) => async (dispatch, getState) => {
  // NB. Databases list includes name from settings
  await dispatch(thunks.loadAllDatabases());

  // NB. Restart sync in case sync url has changed
  await dispatch(thunks.restartDbSync(db));

  return isCurrentDb(db, getState)
    ? dispatch(thunks.loadAllSettings())
    : Promise.resolve('Ignoring event for other db');
};

export const syncChanged = (db, syncStatus) => (dispatch, _getState) => {
  dispatch(actions.syncChanged({ db, syncStatus }))
};

export const restartSync = () => (dispatch, getState) => {
  const { selectedDatabaseId } = getState();
  return selectedDatabaseId
    ? dispatch(thunks.restartDbSync(selectedDatabaseId))
    : Promise.resolve('Can not restart sync, no db selected');
};

export const startAllDbSync = () => (dispatch, getState) => {
  const { databases } = getState();
  const dispatched = databases.map(db => dispatch(thunks.restartDbSync(db.id)))
  return Promise.all(dispatched);
};

export const restartDbSync = (db) => async (_dispatch, _getState, { commands, queries }) => {
  const settings = await queries.getSettings(db);
  const { syncUrl } = (settings || {});
  return commands.startSync(db, syncUrl);
};

export const selectDatabase = (id) => async (dispatch, getState, { commands, queries }) => {
  const settings = await queries.getAppSettings();
  const updatedSettings = { ...settings, selectedDatabaseId: id };
  await commands.updateAppSettings(updatedSettings);

  await dispatch(thunks.logMessage('Selecting database:', id));
  dispatch(actions.databaseSelected(id));

  return Promise.all([
    dispatch(thunks.loadAllRecords()),
    dispatch(thunks.loadAllTopics()),
    dispatch(thunks.loadAllSettings()),
  ]);
};

export const addDatabase = () => async (dispatch, _getState, { commands }) => {
  const newDbId = generateId();

  await commands.createDatabase(newDbId);

  await dispatch(thunks.loadAllDatabases());
  await dispatch(thunks.selectDatabase(newDbId));
  await dispatch(thunks.restartDbSync(newDbId));
};
