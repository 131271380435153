import React, { Component } from 'react';

import DatabasesDialog from '../components/DatabasesDialog';
import { DatabasesButton } from './Layout.components'

class SyncDialogManager extends Component {
  state = {
    isDialogOpen: false,
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  }

  openDialog = () => {
    this.setState({ isDialogOpen: true });
  }

  render() {
    const { isDialogOpen } = this.state;

    return (
      <React.Fragment>
        <DatabasesButton onClick={this.openDialog} />
        <DatabasesDialog onClose={this.closeDialog} open={isDialogOpen} />
      </React.Fragment>
    );
  }
}

export default SyncDialogManager;
