import Signal from 'signals';
import PouchDB from 'pouchdb-browser';

const defaultFactory = (id) => new PouchDB(id);

export default class DatabasesManager {
  /**
   * Create a manager backed by the given database.
   * @param masterDb - the PouchDB database holding a list of databases.
   * @param options.dbFactory - allow override for testing
   */
  constructor(masterDb, options = {}) {
    const { dbFactory } = options;

    this.masterDb = masterDb;
    this.dbFactory = dbFactory || defaultFactory;

    this.onChange = new Signal();
  }

  async getAllIds() {
    const result = await this.masterDb.allDocs({ include_docs: true});
    return result.rows
      .filter(row => row.doc.db_name)
      .map(row => row.doc.db_name);
  }

  async exists(id) {
    const ids = await this.getAllIds();
    return ids.includes(id);
  }

  async get(id) {
    if (!await this.exists(id)) {
      throw new Error(`Database "${id}" not found`);
    }

    return await this.dbFactory(id);
  }

  async create(id) {
    if (await this.exists(id)) {
      throw new Error(`Database "${id}" already exists`);
    }

    await this.masterDb.put({ _id: id, db_name: id });

    const newDb = await this.dbFactory(id);

    this.onChange.dispatch({ type: 'created', id });

    return newDb;
  }

  async delete(id) {
    const existingDb = await this.get(id);

    const record = await this.masterDb.get(id);
    await this.masterDb.put({ ...record, _deleted: true });

    await existingDb.destroy();

    this.onChange.dispatch({ type: 'deleted', id });
  }

  subscribe(fn) {
    this.onChange.add(fn);
    return () => this.onChange.remove(fn);
  }

  static for(masterDbId) {
    const masterDb = defaultFactory(masterDbId);
    return new DatabasesManager(masterDb);
  }
}
