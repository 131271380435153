import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const ToggleButton = ({ toggled, ...rest }) => (
  <IconButton {...rest}>
    { toggled ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
  </IconButton>
);

export default ToggleButton;