import React, { PureComponent } from 'react';

import EditFormBase from './EditFormBase';
import { Text } from './Forms';
import { withDomChange } from './domUtilities';
import DatabaseSyncMessage from './DatabaseSyncMessage';
import DeleteDatabaseDialog from './DeleteDatabaseDialog'
import TopicsPicker from './TopicsPicker'

class SettingsForm extends PureComponent {

  onChange = (field) => (value) => {
    this.props.onSettingChanged(field, value);
  }

  render() {
    const { topics, settings, onSave, onCancel, onDelete, enableSave } = this.props;

    const { syncUrl, displayName, topicsOrder, hiddenTopics } = settings;

    return (
      <EditFormBase
        onSave={onSave}
        onCancel={onCancel}
        onDelete={onDelete}
        isValid={enableSave}
        dialog={DeleteDatabaseDialog}
        saveLabel="Save"
        cancelLabel="Reset"
      >

        <Text
          label="Baby name"
          margin="normal"
          fullWidth={true}
          value={displayName || ''}
          onChange={withDomChange(this.onChange('displayName'))}
          helperText={'Defaults to "Baby Tracker"'}
        />

        <Text
          label="Sync server URL"
          margin="normal"
          fullWidth={true}
          value={syncUrl || ''}
          onChange={withDomChange(this.onChange('syncUrl'))}
          helperText={<DatabaseSyncMessage />}
        />

        <TopicsPicker
          label="Record types"
          helperText="Customise the record types available for new records (does not affect existing records)"
          margin="normal"
          fullWidth={true}
          topics={topics}
          order={topicsOrder}
          onOrderChange={this.onChange('topicsOrder')}
          hidden={hiddenTopics}
          onHiddenChange={this.onChange('hiddenTopics')}
        />

      </EditFormBase>
    );
  }

}

export default SettingsForm;
