import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import * as Dates from '../data/dates';
import { isMoment, intersects } from '../data/queries';
import { TextButton } from './Forms';

class RecordGraph extends Component {

  buildTimeState = () => {
    const now = new Date();
    const today = Dates.startOf(now);
    return { now, today };
  }

  state = {
    ...this.buildTimeState(),
    numberOfDays: 20,
  }

  onShowMore = () => {
    const numberOfDays = this.state.numberOfDays + 20;
    this.setState({ numberOfDays });
  }

  updateTimeState = () => {
    this.setState(this.buildTimeState());
  }

  componentDidMount() {
    this.updateIntervalId = setInterval(this.updateTimeState, 1000 * 30);
  }

  componentWillUnmount() {
    clearInterval(this.updateIntervalId);
  }

  render() {
    const { records } = this.props;
    const { now, today, numberOfDays } = this.state;

    const earliestDay = Dates.getDaysBefore(today, numberOfDays);
    const dates = Dates.getDatesInRange(earliestDay, today, { latestFirst: true });

    return (
      <Grid container spacing={0}>
        <MonthHeaderRow key={'first'} day={today} />
        {
          dates.reduce((acc, day) => {
            const row = <DayTimeline key={day} records={records} day={day} now={now} />;
            const monthHeaderRow = isFirstOfMonth(day)
              ? <MonthHeaderRow key={`m:${day}`} day={getPreviousDay(day)} />
              : null;
            return acc.concat(row, monthHeaderRow);
          }, [])
        }
        <Grid item xs={12} container justify="center">
          <TextButton onClick={this.onShowMore}>Show more</TextButton>
        </Grid>
      </Grid>
    );
  }

}

const MonthHeaderRow = ({ day }) => (
  <Grid item xs={12} style={{
      backgroundColor: '',
  }}>
    <Typography variant="body2" style={{
      textAlign: 'center',
      fontSize: '75%',
    }}>
      { Dates.toFormat('MMM YYYY')(day) }
    </Typography>
  </Grid>
);

const isFirstOfMonth = (day) =>
  day.getDate() === 1;

const getPreviousDay = (day) =>
  new Date(day.getTime() - 1000*60*60*24);

const DayTimeline = ({ records, day, now }) => {
  const recordsForDay = records.filter(intersects({
    since: day,
    until: Dates.endOf(day),
  }));

  const dotw = day.getDay();
  const isWeekend = dotw === 0 || dotw === 6;

  return (
    <Grid item xs={12} container spacing={0} alignItems="center" style={{
        backgroundColor: isWeekend ? 'Gainsboro': '',
    }}>
      <Grid item xs={1}>
        <Typography variant="body2" style={{textAlign: 'center'}}>{ Dates.toFormat('D')(day) }</Typography>
      </Grid>
      <Grid item xs style={{
        borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
        padding: 1,
      }}>
        <svg style={{ display: 'block', width: '100%', height: '20px' }}>
          {
            recordsForDay.map(record => (
              <TimelineRecord
                key={getKey(record)}
                day={day}
                now={now}
                record={record}
              />
            ))
          }
          <NowMarker day={day} now={now} />
        </svg>
      </Grid>
    </Grid>
  );
};

const getKey = ({ type, data: { start } }) =>
  `record:${type}:${start.getTime()}`;

const TimelineRecord = ({ day, record, now }) => {
  const { data: { start, end } } = record;

  return isMoment(record) ?
    <TimelineMoment day={day} start={start} /> :
    <TimelinePeriod day={day} start={start} end={end} now={now} />;
};

const TimelineMoment = ({ day, start }) => {
  const startMoment = Dates.findLatest([start, day]);

  const xOffset = getDayFraction(day, startMoment);

  return (
    <rect
      // Position
      x={pcnt(xOffset)}
      y="30%"
      width={pcnt(MinimumWidthFraction)}
      height="40%"
      // Styling
      stroke="rgba(0,0,0,0.8)"
      strokeWidth="0.5"
      fill="black"
    />
  );
};

const TimelinePeriod = ({ day, start, end, now }) => {
  const startMoment = Dates.findLatest([start, day]);
  const endMoment = Dates.findEarliest([end, now, Dates.endOf(day)]);

  const xOffset = getDayFraction(day, startMoment);
  const widthOffset = Math.max(
    getDayFraction(startMoment, endMoment),
    MinimumWidthFraction
  );

  return (
    <rect
      // Position
      x={pcnt(xOffset)}
      y="20%"
      width={pcnt(widthOffset)}
      height="60%"
      // Styling
      stroke="rgba(0,0,0)"
      strokeWidth="0.5"
      fill="rgba(20,20,255,0.5)"
    />
  );
};

const MinimumWidthFraction = 0.004;

const pulseStyles = {
  pulse: {
    animation: '$nowPulse 0.8s linear infinite alternate',
  },
  '@keyframes nowPulse': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
};

const _NowMarker = ({ day, now, classes }) => {
  if (now < day) return null;
  if (now - day > Dates.dayInMs) return null;

  const xOffset = getDayFraction(day, now);

  return (
    <rect
      // Position
      x={pcnt(xOffset)}
      y="0"
      width="2"
      height="100%"
      // Styling
      fill="rgba(255,20,20,0.8)"
      className={classes.pulse}
    />
  );
};

const NowMarker = withStyles(pulseStyles)(_NowMarker);

const getDayFraction = (start, end) =>
  (end.getTime() - start.getTime()) / Dates.dayInMs;

const pcnt = (val) =>
  `${100 * val}%`;

export default RecordGraph;
