import BottleFeed from './BottleFeed';
import BreastFeed from './BreastFeed';
import Contraction from './Contraction';
import NappyChange from './NappyChange';
import Moment from './Moment';
import Sleep from './Sleep';
import TherapySession from './TherapySession';
import Measurement from './Measurement';
import Cry from './Cry';

import Unsupported from './Unsupported';

const map = {
  'bottle-feed': BottleFeed,
  'breast-feed': BreastFeed,
  'contraction': Contraction,
  'nappy-change': NappyChange,
  'note': Moment,
  'sleep': Sleep,
  'wash': Moment,
  'food': Moment,
  'pop-in': Moment,
  'therapy-session': TherapySession,
  'measurement': Measurement,
  'cry': Cry,
};

const forTopic = (topicId) =>
  map[topicId] || Unsupported;

export default {
  forTopic,
};
