import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (thunkContext) => {
  const thunkMiddleware = thunk.withExtraArgument(thunkContext);
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
  return createStore(reducer, enhancer);
};
