import { isNil } from 'lodash/fp';

export const secondInMs = 1000;
export const minuteInMs = secondInMs * 60;
export const hourInMs = minuteInMs * 60;
export const dayInMs = hourInMs * 24;

export const formatDuration = (durationInMs) => {
  if (isNil(durationInMs)) return null;

  const days = Math.floor(durationInMs / dayInMs);
  const hours = Math.floor(durationInMs / hourInMs) % 24;
  const minutes = Math.floor(durationInMs / minuteInMs) % 60;
  const seconds = Math.floor(durationInMs / secondInMs) % 60;

  const message = [
    days > 0    ? `${days}d`    : null,
    hours > 0   ? `${hours}h`   : null,
    minutes > 0 ? `${minutes}m` : null,
    hours === 0 ? `${seconds}s` : null,
  ].filter(Boolean).join(' ');

  return message;
};

export const rawDuration = ({ start, end, duration }) => {
  if (
    isNil(duration) &&
    (isNil(start) || isNil(end))
  ) {
    return null;
  }

  return !isNil(duration) ? duration : end - start;
};
