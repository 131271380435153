import { createAction } from 'redux-act';

export const recordsStarted = createAction('records started');
export const recordsLoaded = createAction('records loaded');
export const recordChanged = createAction('record changed');
export const recordsChanged = createAction('records changed');

export const topicsStarted = createAction('topics started');
export const topicsLoaded = createAction('topics loaded');

export const settingsUpdated = createAction('settings updated');

export const syncChanged = createAction('sync changed');

export const databasesLoaded = createAction('databases loaded');

export const databaseSelected = createAction('database selected');

export const recordSelected = createAction('record selected');
export const recordDeselected = createAction('record deselected');
