import { get, getOr, mapValues, flow, values, orderBy } from 'lodash/fp';

import { disconnectedState } from './redux/reducer.sync';
import { merge } from './topics';

// Utilities

export const withSelectors = (selectorMap) => (state, ownProps) =>
  mapValues(selector => selector(state, ownProps))(selectorMap);

export const mergeResults = (fns) => (...args) =>
  fns.reduce((acc, fn) => Object.assign(acc, fn(...args)), {});

// Selectors

export const currentSyncStatus = ({ selectedDatabaseId, sync }) =>
  selectedDatabaseId
    ? getOr(disconnectedState, selectedDatabaseId, sync)
    : disconnectedState;

export const showSyncStatus = (state) =>
  currentSyncStatus(state).status !== 'connected';

export const selectedDatabase = ({ selectedDatabaseId, databases }) =>
  selectedDatabaseId
    ? databases.find(db => db.id === selectedDatabaseId)
    : null;

export const records = ({ recordsById }) =>
  recordsById
    ? flow(values, orderBy('data.start', 'desc'))(recordsById)
    : recordsById;

export const record = (recordId) => ({ recordsById }) =>
  recordsById ? recordsById[recordId] : null;

export const topics = get('topics');

export const topic = (topicId) => (state) =>
  topics(state).find(t => t.id === topicId);

export const databases = get('databases');

export const settings = get('settings');

export const selectedRecord = get('selectedRecord');

export const availableTopics = (state) => {
  const allTopics = topics(state);
  const sttings = settings(state);

  const topicsOrder = getOr([], 'topicsOrder', sttings);
  const hiddenTopics = getOr({}, 'hiddenTopics', sttings);

  const topicIds = allTopics.map(get('id'));
  const orderedIds = merge(topicIds, topicsOrder);

  return orderedIds
    .filter(id => !hiddenTopics[id])
    .map(id => allTopics.find(t => t.id === id));
};
