import React, { Component } from 'react';
import { get } from 'lodash/fp';

import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';

import Duration from '../components/Duration';
import TopicIcon from '../components/TopicIcon';
import SpacedCard from '../components/SpacedCard';
import TighterCardContent from '../components/TighterCardContent';
import { Paragraph } from '../components/Typography';
import { TimeRangeInfo } from '../components/RecordItems/_BaseRecordItem';

const OneDayMs = 1000 * 60 * 60 * 24;

class ReportSummary extends Component {

  onSelect = (report) => () => {
    this.props.onSelect(report);
  }

  render() {
    const { report } = this.props;

    const defaultSubset = report.subsets[0];
    const latestTime = get('results.latestTime', defaultSubset);
    const latestStart = get('results.latestRecord.data.start', defaultSubset);
    const latestEnd = get('results.latestRecord.data.end', defaultSubset);

    const icon = report.icon.topicId
      ? <TopicIcon topicId={report.icon.topicId} style={{ marginRight: '8px' }} />
      : null

    const timeSince = (
      <Duration
        start={ latestTime }
        end={ new Date() }
        thresholdMs={OneDayMs}
        thresholdContent="More than 24hrs ago"
      />
    );

    const startTime = (
      <TimeRangeInfo start={latestStart} end={latestEnd} />
    );

    return (
      <SpacedCard>
        <CardActionArea>
          <TighterCardContent onClick={this.onSelect(report)}>
            <Paragraph>
              {icon}
              {report.name}
            </Paragraph>
            <Typography>Last record: {startTime}</Typography>
            <Typography>Time since last: {timeSince}</Typography>
          </TighterCardContent>
        </CardActionArea>
      </SpacedCard>
    );
  }
}


export default ReportSummary;
