import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { withSwitchChange } from '../components/domUtilities';

const RecordsFilter = ({
    withGaps=true,
    showGaps,
    onShowGapsChanged,

    filterTopics,
    onFilterTopicsChanged,

    topics,
    topicsToShow,
    onTopicsToShowChanged,

    withNotes=false,
    showNotes,
    onShowNotesChanged,
}) => (
  <Grid container style={{ marginBottom: '1em' }} justify="space-between">

    { withGaps && 
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              checked={showGaps}
              onChange={withSwitchChange(onShowGapsChanged)}
              value="showGaps"
            />
          }
          label="Show gaps between events?"
        />
      </Grid>
    }

    <Grid item xs={12} sm={6}>
      <FormControlLabel
        control={
          <Switch
            checked={filterTopics}
            onChange={withSwitchChange(onFilterTopicsChanged)}
            value="filterTopics"
          />
        }
        label="Filter by topic type?"
      />
      <Collapse in={filterTopics}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Select topics to show</FormLabel>
          <FormGroup>
            {
              topics.map(topic => (
                <FormControlLabel
                  key={topic.id}
                  control={
                    <Checkbox
                      checked={topicsToShow[topic.id]}
                      onChange={withSwitchChange(onTopicsToShowChanged(topic.id))}
                      value={topic.id}
                    />
                  }
                  label={topic.name}
                />
              ))
            }
          </FormGroup>
        </FormControl>
      </Collapse>
    </Grid>

    { withNotes && 
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              checked={showNotes}
              onChange={withSwitchChange(onShowNotesChanged)}
              value="showNotes"
            />
          }
          label="Show item notes?"
        />
      </Grid>
    }

  </Grid>
);

export default RecordsFilter;
