import React, { Fragment } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';

import EditForm from './_EditForm';
import { FancyDateTime, TextArea, Select, NumberField } from '../Forms'

const unitsByType = {
  'Length': 'cm',
  'Mass': 'kg',
  'Head circumference': 'cm',
};
export const unitForType = (type) => unitsByType[type] || '';

class EditForm_Measurement extends EditForm {

  constructor(props, context) {
    super(props, context, 'measurement');
  }

  state = {
    start: EditForm.getField('data.start')(this.props.record),
    type: EditForm.getField('data.type')(this.props.record),
    quantity: EditForm.getField('data.quantity')(this.props.record),
    notes: EditForm.getField('data.notes')(this.props.record),
  }

  buildRecordData = () => {
    const { start, type, quantity, notes } = this.state;

    return {
      start: start || null,
      type,
      quantity,
      notes,
    };
  }

  renderTopicFields = () => {
    const { start, type, quantity, notes } = this.state;

    return (
      <Fragment>

        <FancyDateTime
          label="Start"
          margin="normal"
          value={start}
          onChange={this.onChange('start')}
        />

        <Select
          label="Type"
          margin="normal"
          value={type || ''}
          onChange={this.onDomChange('type')}
        >
          <option value="">--Select--</option>
          <option>Length</option>
          <option>Mass</option>
          <option>Head circumference</option>
        </Select>

        <NumberField
          label="Value"
          InputProps={{
            endAdornment: <InputAdornment>{unitForType(type)}</InputAdornment>
          }}
          value={quantity || ''}
          onChange={this.onDomChange('quantity')}
        />

        <TextArea
          label="Notes"
          margin="normal"
          rowsMax={8}
          value={notes || ''}
          onChange={this.onDomChange('notes')}
        />
        
      </Fragment>
    );
  }

}
export default EditForm_Measurement;
