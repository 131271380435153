import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class TopicQuickAdd extends Component {
  state = {
    menuAnchor: null,
  };

  showMenuOver = (menuAnchor) => {
    this.setState({ menuAnchor });
  };

  hideMenu = () => {
    this.setState({ menuAnchor: null });
  };

  availableOptions = () => {
    const { topic } = this.props;
    const config = optionsByType[topic.id]
    return config ? config.values : [];
  };

  onClick = (e) => {
    const { topic, onStart } = this.props;

    if (this.availableOptions().length > 0) {
      this.showMenuOver(e.currentTarget);
    } else {
      onStart(topic, {});
    }
  };

  selectOption = (value) => {
    const { topic, onStart } = this.props;

    this.hideMenu();

    const config = optionsByType[topic.id];
    onStart(topic, { [config.name]: value });
  };

  render() {
    const { menuAnchor } = this.state;

    return (
      <>
        <IconButton onClick={this.onClick}>
          <PlayCircleFilledIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={this.hideMenu}
        >
          {
            this.availableOptions().map((option, i) => (
              <MenuItem
                key={i}
                onClick={() => this.selectOption(option)}
                children={option}
              />
            ))
          }
        </Menu>
      </>
    )
  }
}

const optionsByType = {
  'breast-feed': { name: 'side', values: ['Left', 'Right'] },
  'bottle-feed': { name: 'type', values: ['Breast milk', 'Formula'] },
  'nappy-change': { name: 'type', values: ['Dry', 'Wet', 'Dirty', 'Mixed'] },
};

export default TopicQuickAdd;
