import parse from './parse';
import convert from './convert';
import importRecords from './importRecords';

// Timing utilities

const waitFor = (ms, result, ok=true) =>
  new Promise((resolve, reject) =>
    setTimeout(() => (ok ? resolve : reject)(result), ms)
  );

const after = (ms) => (target) => {
  const wait = waitFor(ms, 'ignored');
  return Promise
    .all([wait, target])
    .then(
      ([_, result]) => Promise.resolve(result),
      (error) => wait.then((_) => Promise.reject(error))
    );
};

const withMinDuration = (fn, ms) => (...args) =>
  after(ms)(fn(...args));

// Service

const getActions = (addRecords) => {
  return {
    parse: withMinDuration(parse, 750),
    convert: withMinDuration(convert, 750),
    importRecords: withMinDuration(importRecords(addRecords), 750),
  }
};

export default getActions;
