import React, { Component } from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';

import { isType } from '../data/queries';
import { withSelectors, records } from '../data/selectors';

import TopBar from '../components/TopBar';
import MeasurementsChart from '../components/MeasurementsChart';
import { Paragraph } from '../components/Typography';
import withLoading from '../components/withLoading';

class MeasurementCharts extends Component {

  render() {
    console.log('MeasurementCharts', this.props);
    const { records } = this.props;

    const measurements = records.filter(isType('measurement'));

    return (
      <React.Fragment>

        <TopBar>Measurement Charts</TopBar>

        <React.Fragment>
          <MeasurementsChart records={measurements} width="100%" height="75%" />
          <Paragraph variant='body2'>
            This chart shows only the length and weight measurements.
          </Paragraph>
        </React.Fragment>

      </React.Fragment>
    );
  }
}

export default flow([
  withLoading(props => props.records === null),
  connect(withSelectors({ records })),
])(MeasurementCharts);
