import { createMachine, invoke, reduce, state, transition } from 'robot3';

// Utilities

const identity = x => x;

const mergeValueAs = (name, value) =>
  reduce((ctx, _) => ({ ...ctx, [name]: value }))

const mergeDataAs = (name) =>
  reduce((ctx, ev) => ({ ...ctx, [name]: ev.data }))

const mergeErrorAs = (name) =>
  reduce((ctx, ev) => ({ ...ctx, [name]: ev.error }))

// Machines

const conversionMachine = createMachine({
  idle: state(
    transition('convert', 'parsing'),
    transition('finished', 'end'),
  ),
  parsing: invoke((ctx, _) => ctx.parse(ctx.file),
    transition('done', 'transforming', mergeDataAs('rows')),
    transition('error', 'error', mergeErrorAs('errors')),
  ),
  transforming: invoke(
    (ctx, _) => ctx.convert(ctx.rows),
    transition('done', 'previewing', mergeDataAs('pendingRecords')),
    transition('error', 'error', mergeErrorAs('errors')),
  ),
  previewing: state(
    transition('import', 'importing'),
    transition('finished', 'end'),
  ),
  importing: invoke(
    (ctx, _) => ctx.importRecords(ctx.pendingRecords, Date.now(), ctx.file.name),
    transition('done', 'summary', mergeDataAs('results')),
    transition('error', 'error', mergeErrorAs('errors')),
  ),
  summary: state(
    transition('finished', 'end')
  ),
  error: state(
    transition('finished', 'end')
  ),
  end: state(),
}, identity);

export const machine = createMachine({
  picking: state(
    transition('pick', 'picked', mergeDataAs('file'))
  ),
  picked: invoke(conversionMachine,
    transition('done', 'picking', mergeValueAs('file', null))
  ),
}, (services) => ({ ...services, file: null }));
