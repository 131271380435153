import { createReducer } from 'redux-act';

import { topicsStarted, topicsLoaded } from './actions';

const initState = [];

export default createReducer({
  [topicsStarted]: (_state) => initState,
  [topicsLoaded]: (_state, data) => data,
}, initState);
