import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSelectors, databases } from '../data/selectors';
import { addDatabase, selectDatabase } from '../data/redux/thunks';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { FaBaby } from 'react-icons/fa';

import WideDialog from './WideDialog';
import { Paragraph } from './Typography';
import { TextButton } from './Forms';

class DatabasesDialog extends Component {
  render() {
    const { onClose, databases, selectDatabase, addDatabase, ...other } = this.props;

    const closeThen = (fn) => (...args) => {
      onClose();
      fn(...args);
    };

    return (
      <WideDialog
	onClose={onClose}
	{...other}
      >
	<DialogTitle>Switch to...</DialogTitle>
	<DialogContent>
          <List>
          {
            databases.map(database => (
              <ListItem
                key={database.id}
                button
		onClick={closeThen(() => selectDatabase(database.id))}
              >
                <ListItemIcon>
		  <FaBaby />
		</ListItemIcon>
		<ListItemText
		  primary={ database.displayName }
		  secondary={ `ID: ${database.id}` }
		/>
              </ListItem>
            ))
          }
          </List>
	  <Paragraph variant="body2" color="textSecondary">
	    (edit or delete via their settings)
	  </Paragraph>
	</DialogContent>
	<DialogActions>
	  <TextButton onClick={closeThen(addDatabase)}>
	    + Add new child
	  </TextButton>
	</DialogActions>
      </WideDialog>
    );
  }
};

const mapStateToProps = withSelectors({ databases });
const mapDispatch = { selectDatabase, addDatabase };

export default connect(mapStateToProps, mapDispatch)(DatabasesDialog);
