import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Layout from './components/Layout';
import DatabaseSyncManager from './components/DatabaseSyncManager'
import EditDialog from './components/EditDialog'
import ErrorBoundary from './components/ErrorBoundary'

import Records from './pages/Records'
import RecordsGraph from './pages/RecordsGraph'
import Settings from './pages/Settings'
import Reports from './pages/Reports'
import Report from './pages/Report'
import Log from './pages/Log'
import Data from './pages/Data'
import SleepTrends from './pages/SleepTrends'
import MeasurementCharts from './pages/MeasurementCharts'
import About from './pages/About'
import Diagnostics from './pages/Diagnostics'
import ChangeLog from './pages/ChangeLog'

import UnknownPage from './pages/UnknownPage'

const theme = createMuiTheme();

const App = ({ history }) => (
  <Router history={history}>
    <MuiThemeProvider theme={theme}>
      <ErrorBoundary>
        <Layout>
          <DatabaseSyncManager />
          <EditDialog />
          <Switch>
            <Route exact path="/records" component={Records} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/graph" component={RecordsGraph} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/reports/:reportId" component={Report} />
            <Route exact path="/log" component={Log} />
            <Route exact path="/data" component={Data} />
            <Route exact path="/sleep-trends" component={SleepTrends} />
            <Route exact path="/measurement-charts" component={MeasurementCharts} />
            <Route exact path="/about" component={About} />
            <Route exact path="/diagnostics" component={Diagnostics} />
            <Route exact path="/changelog" component={ChangeLog} />
            <Redirect exact from="/" to="/records" />
            <Route component={UnknownPage} />
          </Switch>
        </Layout>
      </ErrorBoundary>
    </MuiThemeProvider>
  </Router>
);

export default App;
