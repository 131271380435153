import React, { Component } from 'react';
import { get } from 'lodash/fp';

import EditFormBase from '../EditFormBase';

class EditForm extends Component {

  constructor(props, context, topicId) {
    super(props, context);
    this.topicId = topicId;
  }

  static getField(path) {
    return get(path);
  }

  onDomChange = (field) => (e) => {
    this.setState({ [field]: e.target.value });
  }

  onChange = (field) => (value) => {
    this.setState({ [field]: value });
  }

  buildRecord = () => {
    const { record } = this.props;

    const newRecord = {
      type: this.topicId,
      data: this.buildRecordData(),
      meta: {
        timestamp: Date.now(),
      },
    };

    // NOTE: Merge over the top of any existing fields
    return { ...record, ...newRecord };
  }

  buildRecordData = () => {
    throw new Error('Must be implemented');
  }

  isValid = () => {
    const { start } = this.buildRecordData();
    return !!start;
  }

  onSave = () => {
    this.props.onSave(this.buildRecord());
  }

  onDelete = () => {
    this.props.onDelete(this.props.record);
  }

  renderTopicFields = () => {
    throw new Error('Must be implemented');
  }

  render() {
    const { record } = this.props;

    return (
      <EditFormBase
        onSave={this.onSave}
        onCancel={this.props.onCancel}
        onDelete={this.props.onDelete && this.onDelete}
        isValid={this.isValid()}
        saveLabel={!!record ? 'Update' : 'Create'}
      >

        { this.renderTopicFields() }

      </EditFormBase>
    );
  }

}

export default EditForm;
