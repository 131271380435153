import { createReducer } from 'redux-act';

import { databaseSelected, settingsUpdated } from './actions';

const initialState = null;

export default createReducer({
  [databaseSelected]: (_state, _database) => initialState,
  [settingsUpdated]: (_state, settings) => settings,
}, initialState);
