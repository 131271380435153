import { isFunction, isNumber, isString, isArray } from 'lodash/fp';

import { importError, argumentError } from './errors';

const importRecords = (addRecords) => {
  // Validate dev args before invoked
  if (!isFunction(addRecords)) throw new Error('addRecords must be a function');

  return (pendingRecords, timestamp, filename) => {
    if (!isArray(pendingRecords)) return Promise.reject([argumentError('pendingRecords must be an array')]);
    if (!isNumber(timestamp)) return Promise.reject([argumentError('timestamp must be a number')]);
    if (!isString(filename)) return Promise.reject([argumentError('filename must be a string')]);

    const decorate = (record) => ({
      ...record,
      meta: { ...record.meta, timestamp },
      imported: { timestamp, filename },
    });

    const records = pendingRecords.map(decorate)

    return addRecords(records)
      .catch(error => Promise.reject([importError(error)]));
  };
};

export default importRecords;
