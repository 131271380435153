import React from 'react';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles';

const ChipSelect = ({ label, options, value, onChange, ...rest }) => (
  <FormControl {...rest}>
    <FormHelperText>
      { label }
    </FormHelperText>
    <Grid container spacing={1} justify="center">
      {
        options.map((option, i) => {
          const selected = option === value
          return (
            <Grid item key={i}>
              <StyledChip
                selected={selected}
                onClick={() => onChange(selected ? null : option)}
                label={option}
              />
            </Grid>
          )
        })
      }
    </Grid>
  </FormControl>
);

const LinkChip = ({ selected, classes, ...rest }) => (
  <Chip
    color="primary"
    className={classNames(classes.root, selected && classes.selected)}
    variant={selected ? 'default' : 'outlined'}
    {...rest}
  />
)

const chipStyles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    // NOTE: Set padding as large as still allows all subtypes on one line
    padding: theme.spacing(1.3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
})

const StyledChip = withStyles(chipStyles)(LinkChip)

export default ChipSelect;
