import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';

import { withSelectors, topics, selectedRecord } from '../data/selectors';
import { addRecord, updateRecord, deleteRecord, finishEditing } from '../data/redux/thunks';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import WideDialog from './WideDialog';
import TopicIcon from './TopicIcon';
import TopBar from './TopBar';
import EditForms from './EditForms';

const isRecordPersisted = (record) => !!record._id

class EditDialog extends Component {
  onSave = (record) => {
    const { updateRecord, addRecord, finishEditing } = this.props;

    // Hmm, not sure about sniffing for this here
    const task = isRecordPersisted(record) ? updateRecord : addRecord;
    task(record).then(finishEditing);
  }

  onDelete = (record) => {
    const { deleteRecord, finishEditing } = this.props;

    deleteRecord(record).then(finishEditing);
  }
  
  onCancel = () => {
    this.props.finishEditing();
  }

  render() {
    console.log('EditDialog', this.props);
    const { selectedRecord, topics, finishEditing } = this.props;

    if (!selectedRecord) {
      return null;
    }

    const topicId = get('type', selectedRecord);
    const topic = topics.find(t => t.id === topicId);

    const EditForm = EditForms.forTopic(topic.id);

    const onDelete = isRecordPersisted(selectedRecord) ? this.onDelete : null;

    return (
      <WideDialog
        open={selectedRecord}
	onClose={finishEditing}
      >
	<DialogTitle disableTypography>
          <TopBar icon={<TopicIcon topicId={topic.id}/>}>
            { topic.name }
          </TopBar>
        </DialogTitle>
	<DialogContent>
          <EditForm
            record={selectedRecord}
            onSave={this.onSave}
            onCancel={this.onCancel}
            onDelete={onDelete}
          />
        </DialogContent>
      </WideDialog>
    );
  }
};

const mapStateToProps = withSelectors({ topics, selectedRecord });
const mapDispatch = { addRecord, updateRecord, deleteRecord, finishEditing };

export default connect(mapStateToProps, mapDispatch)(EditDialog);
