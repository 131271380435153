import React, { Component }  from 'react';

import changeLogPath from '../../CHANGELOG.md';
import getDisplayName from '../components/getDisplayName';

// HOC that adds the change log details in a `changelog` prop
const withChangeLog = () => (WrappedComponent) => {
  class WithChangeLog extends Component {
    constructor(props) {
      super(props);
      this.state = { changelog: null };
    }

    componentDidMount() {
      fetch(changeLogPath)
        .then(
          response => response.ok
            ? response.text()
            : `ERROR: ${response.statusText} [${response.status}]`,
          error => `ERROR: ${error}`,
        )
        .then(changelog => this.setState({ changelog }))
    }

    render() {
      const { changelog } = this.state;
      return <WrappedComponent {...this.props} changelog={changelog} />;
    }
  }
  WithChangeLog.displayName = `WithChangeLog(${getDisplayName(WrappedComponent)})`;
  return WithChangeLog;
}

export default withChangeLog;
