import { argumentError } from './errors';
import parseCsv from './parseCsv';
import parseJson from './parseJson';

const parse = (file) =>
  new Promise((resolve, reject) => {
    if (file.name.endsWith('.csv')) {
      resolve(parseCsv(file));
    } else if (file.name.endsWith('.json')) {
      resolve(parseJson(file));
    } else {
      reject([argumentError(`No parser found for ${file.name} (try .csv or .json)`)])
    }
  });

export default parse;
