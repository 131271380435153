import React from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import { withSelectors, currentSyncStatus } from '../data/selectors';

const SyncStatusButton = ({ classes, currentSyncStatus, onClick }) => (
  <IconButton
    aria-label="Sync status"
    className={classNames(
      classes.root,
      classes[`status-${currentSyncStatus.status}`],
      currentSyncStatus.error && classes.error,
    )}
    onClick={onClick}
  >
    <SyncIcon className={classNames(
      ['active', 'connecting'].includes(currentSyncStatus.status) && classes.rotating
    )} />
  </IconButton>
);

const styles = theme => ({
  'root': {
    color: 'inherit',
  },
  'status-disconnected': {
    color: theme.palette.grey[600],
  },
  'status-connecting': {
    color: theme.palette.primary.light,
  },
  'status-connected': {
    color: 'inherit',
  },
  'status-active': {
    color: 'inherit',
  },
  'error': {
    color: theme.palette.error.light,
  },
  rotating: {
    animation: '$rotation 3s infinite linear',
  },
  [`@keyframes rotation`]: {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(-360deg)' },
  },
});

const mapStateToProps = withSelectors({ currentSyncStatus });

export default flow([
  withStyles(styles),
  connect(mapStateToProps),
])(SyncStatusButton);
