export default [
    { id: 'breast-feed', name: 'Breast feed' },
    { id: 'bottle-feed', name: 'Bottle feed' },
    { id: 'nappy-change', name: 'Nappy change' },
    { id: 'sleep', name: 'Sleep' },
    { id: 'pop-in', name: 'Pop in' },
    { id: 'cry', name: 'Cry' },
    { id: 'note', name: 'Note' },
    { id: 'wash', name: 'Wash' },
    { id: 'therapy-session', name: 'Therapy session' },
    { id: 'food', name: 'Food' },
    { id: 'measurement', name: 'Measurement' },
    { id: 'contraction', name: 'Contraction' },
];
