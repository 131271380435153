import React, { Fragment } from 'react';

import EditForm from './_EditForm';
import { FancyDateTime, TextArea } from '../Forms'

class EditForm_Sleep extends EditForm {

  constructor(props, context) {
    super(props, context, 'sleep');
  }

  state = {
    start: EditForm.getField('data.start')(this.props.record),
    end: EditForm.getField('data.end')(this.props.record),
    notes: EditForm.getField('data.notes')(this.props.record),
  }

  buildRecordData = () => {
    const { start, end, notes } = this.state;

    return {
      start: start || null,
      end: end || null,
      notes,
    };
  }

  renderTopicFields = () => {
    const { start, end, notes } = this.state;

    return (
      <Fragment>

        <FancyDateTime
          label="Start"
          margin="normal"
          value={start}
          onChange={this.onChange('start')}
        />

        <FancyDateTime
          label="End"
          margin="normal"
          value={end}
          onChange={this.onChange('end')}
        />

        <TextArea
          label="Notes"
          margin="normal"
          rowsMax={8}
          value={notes || ''}
          onChange={this.onDomChange('notes')}
        />
        
      </Fragment>
    );
  }

}
export default EditForm_Sleep;
