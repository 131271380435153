import React  from 'react';

import TopBar from '../components/TopBar';
import ChangeLogInfo from '../components/ChangeLogInfo';

const ChangeLog = () => (
  <React.Fragment>

    <TopBar>Change Log</TopBar>

    <ChangeLogInfo />

  </React.Fragment>
);

export default ChangeLog;
