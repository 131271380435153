import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

class BackButton extends Component {
  onBack = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <IconButton onClick={this.onBack}>
        <ChevronLeftIcon />
      </IconButton>
    );
  }
}

export default withRouter(BackButton);