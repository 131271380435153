import React from 'react';

import Grid from '@material-ui/core/Grid';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';

import EventGap from './EventGap';
import { Span } from './Typography';

const EventGapItem = ({ until, since }) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item>
      <HourglassEmpty style={{ fontSize: 'smaller', color: 'rgba(0,0,0,0.5)' }} />
    </Grid>
    <Grid item>
      <Span style={{ fontSize: 'smaller', color: 'rgba(0,0,0,0.5)' }}>
        <EventGap until={until} since={since} />
      </Span>
    </Grid>
  </Grid>
);

export default EventGapItem;
