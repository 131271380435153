import React, { Fragment } from 'react';

import EditForm from './_EditForm';
import { FancyDateTime, ChipSelect, TextArea } from '../Forms'

class EditForm_NappyChange extends EditForm {

  constructor(props, context) {
    super(props, context, 'nappy-change');
  }

  state = {
    start: EditForm.getField('data.start')(this.props.record),
    type: EditForm.getField('data.type')(this.props.record),
    notes: EditForm.getField('data.notes')(this.props.record),
  }

  buildRecordData = () => {
    const { start, type, notes } = this.state;

    return {
      start: start || null,
      type,
      notes,
    };
  }

  renderTopicFields = () => {
    const { start, type, notes } = this.state;

    return (
      <Fragment>

        <FancyDateTime
          label="At"
          margin="normal"
          value={start}
          onChange={this.onChange('start')}
        />

        <ChipSelect
          label="Type"
          margin="normal"
          value={type || ''}
          onChange={this.onChange('type')}
          options={['Dry', 'Wet', 'Dirty', 'Mixed']}
        />

        <TextArea
          label="Notes"
          margin="normal"
          rowsMax={8}
          value={notes || ''}
          onChange={this.onDomChange('notes')}
        />

      </Fragment>
    );
  }

}

export default EditForm_NappyChange;
