import React, { Component } from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import FloatingActionButton from '../components/FloatingActionButton';
import AddTopicDialog from '../components/AddTopicDialog';
import { withSelectors, availableTopics } from '../data/selectors';
import { quickStart, addTopic } from '../data/redux/thunks';

class FloatingTopicAdd extends Component {

  state = {
    showDialog: false,
  }

  onShowDialog = () => {
    this.setState({ showDialog: true });
  }

  hideDialog = () => {
    this.setState({ showDialog: false });
  }

  onAdd = (topic) => {
    this.hideDialog();
    if (topic) {
      this.props.addTopic(topic);
    }
  }

  onStart = (topic, data) => {
    const { quickStart } = this.props;
    this.hideDialog();
    quickStart(topic, data);
  }

  render() {
    const { availableTopics } = this.props;
    const { showDialog } = this.state;

    return (
      <React.Fragment>

        <FloatingActionButton onClick={this.onShowDialog}>
          <AddIcon/>
        </FloatingActionButton>

        <AddTopicDialog
          topics={availableTopics}
          onClose={this.onAdd}
          onStart={this.onStart}
          open={showDialog}
        />

      </React.Fragment>
    );
  }
}

const mapStateToProps = withSelectors({ availableTopics });

export default flow([
  connect(mapStateToProps, { quickStart, addTopic }),
  withRouter,
])(FloatingTopicAdd);
