import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { DefaultButton } from '../components/Forms';

class ExportLink extends Component {

  constructor(props) {
    super(props)

    this.state = { url: null };
  }

  componentDidMount() {
    const { data } = this.props;
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json],{type: 'text/json;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    this.setState({ url });
  }

  componentWillUnmount() {
    const { url } = this.state;
    URL.revokeObjectURL(url);
  }

  render() {
    const { classes } = this.props;
    const { url } = this.state;
    
    return (
      <DefaultButton href={url} download="records.json" disabled={!url}>
        Export all records
        <CloudDownloadIcon className={classes.rightIcon} />
      </DefaultButton>
    );
  }

}

ExportLink.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

export default withStyles(styles)(ExportLink);
