import React  from 'react';
import { flow } from 'lodash/fp';

import withChangeLog from '../components/withChangeLog';
import withLoading from '../components/withLoading';

const ChangeLogInfo = ({ changelog }) => (
  <pre style={{ whiteSpace: 'pre-line' }}>
    { changelog }
  </pre>
)

export default flow([
  withLoading(props => !props.changelog),
  withChangeLog(),
])(ChangeLogInfo);

