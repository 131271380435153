import React from 'react';
import { flow } from 'lodash/fp';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

import * as Dates from '../data/dates';
import FormattedNumber from './FormattedNumber';

const VolumesBarChart = ({ data, ...others }) => (
  <ResponsiveContainer {...others} >
    <BarChart data={buildData(data)}>
      <CartesianGrid />
      <XAxis
        dataKey="until"
        domain={['dataMin', 'dataMax']}
        type="number"
        name="Until"
        tickFormatter={formatDate}
       />
      <YAxis
        dataKey="volume"
        domain={[0, 'dataMax']}
        type="number"
        tickFormatter={formatVolume}
      />
      <Bar dataKey={'volume'} fill="#88d884" name="Volume" />
      <Tooltip formatter={formatTooltipValue} labelFormatter={formatDateLabel}/>
    </BarChart>
  </ResponsiveContainer>
);

const buildData = data =>
  data
    .map(({ until, volume }) => ({
      until: until.getTime(),
      volume,
    }))

const formatDate = value =>
  Dates.toFormat('Do MMM')(new Date(value));

const formatVolume = value =>
  FormattedNumber({ value, format: '0.[0]' }) + ' ml';

const formatTooltipValue = (value, _name) =>
  formatVolume(value);

const formatDateLabel = flow([parseInt, formatDate]);

export default VolumesBarChart;
