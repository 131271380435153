import React, { Component } from 'react';
import { connect } from 'react-redux';

import packageJson from '../../../package.json';
import { reloadApp, viewLog, viewDiagnostics, viewChangeLog } from '../data/redux/thunks';
import TopBar from '../components/TopBar';
import { Paragraph } from '../components/Typography';
import { TextButton } from '../components/Forms';

class About extends Component {
  render() {
    const { viewLog, viewDiagnostics, viewChangeLog, reloadApp } = this.props;
    return (
      <React.Fragment>

        <TopBar>About</TopBar>

        <Paragraph>The baby tracker where you own the data.</Paragraph>

        <Paragraph>
          All data lives <b>only</b> on your device by default.
          If you want to share data with anyone else then you can
          configure a shared sync server in settings.
        </Paragraph>

        <Paragraph variant="body2">Version: { packageJson.version }</Paragraph>

        <Paragraph>
          <TextButton color="primary" size="small" onClick={viewLog}>
            View log
          </TextButton>
        </Paragraph>

        <Paragraph>
          <TextButton color="primary" size="small" onClick={viewDiagnostics}>
            Diagnostics
          </TextButton>
        </Paragraph>

        <Paragraph>
          <TextButton color="primary" size="small" onClick={viewChangeLog}>
            Change Log
          </TextButton>
        </Paragraph>

        <Paragraph>
          <TextButton color="primary" size="small" onClick={reloadApp}>
            Reload app
          </TextButton>
        </Paragraph>
      </React.Fragment>
    );
  }
}

export default connect(undefined, {
  viewLog,
  viewDiagnostics,
  viewChangeLog,
  reloadApp,
})(About);
