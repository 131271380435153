import Signal from 'signals';
import { orderBy, filter } from 'lodash/fp';

import HardCodedTopics from './HardCodedTopics';

class PouchDbDomainService {

  constructor(db) {
    this.db = db;
    this._onChange = new Signal();

    this.listenForChanges();
  }

  listenForChanges() {
    this.stopListeningForChanges();

    const changes = this.db.changes({
      since: 'now',
      live: true,
      include_docs: true,
    });

    changes.on('change', (change) => {
      const doc = getDocument(change);
      if (isRecordDoc(doc)) {
        this._onChange.dispatch(doc);
      }
    });

    this.unsubscribeToChanges = () => changes.cancel();
  }

  stopListeningForChanges() {
    if (this.unsubscribeToChanges) {
      this.unsubscribeToChanges();
      this.unsubscribeToChanges = null;
    }
  }

  get onChange() {
    return this._onChange;
  }

  /** Unsubscribe a subscription obtained from `this.onChange.add(fn)` */
  unsubscribe(subscription) {
    // HACK: We know the subscriptions are of type SignalContext
    subscription.detach();
  }

  getRecords() {
    return this.db
      .allDocs({
        include_docs: true,
      })
      .then(({rows}) => rows.map(getDocument))
      .then(filter(isRecordDoc)) // Remove design docs
      .then(orderBy('data.start', 'desc'));
  }

  addRecord(record) {
    return this.db
      .post(record);
  }

  addRecords(records) {
    return this.db
      .bulkDocs(records);
  }

  updateRecord(record) {
    return this.db
      .put(record);
  }

  deleteRecord(record) {
    return this.db
      .remove(record);
  }

  getTopics() {
    return Promise.resolve(HardCodedTopics);
  }

}

const getDocument = row => row.doc;
const isRecordDoc = ({ _id } = {}) => _id && !_id.startsWith('_design');

export default PouchDbDomainService;
