/**
 * Create a function that throttles calls to a callback for the given delay, firing
 * on the leading and trailing edges.  Gather all arguments for invocations
 * via given reducer and initial state.
 */
export const gatherArgs = (delayMs, reducer, initial) => (callback) => {
  let intervalId;
  const stopGathering = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    intervalId = null;
  };
  const startGathering = () => {
    intervalId = setInterval(callWithPendingArgs, delayMs);
  };

  let pendingCount;
  let pendingArgs;
  const clearPending = () => {
    pendingCount = 0;
    pendingArgs = initial;
  };
  const addPending = (args) => {
    pendingCount++;
    pendingArgs = reducer(pendingArgs, args);
  };

  const callWithPendingArgs = () => {
    if (pendingCount > 0) {
      callback(pendingArgs);
    } else {
      stopGathering();
    }
    clearPending();
  };

  stopGathering();
  clearPending();

  return (...args) => {
    const isFirstInvocation = intervalId === null;

    if (isFirstInvocation) {
      callback(reducer(pendingArgs, args));
      startGathering();
    } else {
      addPending(args);
    }
  };
};
