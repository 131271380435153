import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import WideDialog from './WideDialog';
import { DefaultButton, SecondaryButton } from './Forms';

const SlideUp = (props) =>(
  <Slide direction="up" {...props} />
);

const DeleteDialog = ({ onDelete, onCancel, ...rest }) => (
  <WideDialog
    TransitionComponent={SlideUp}
    onClose={onCancel}
    {...rest}
  >
    <DialogTitle>Delete this record?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will permanently remove this record from all devices connected
        to this data.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <SecondaryButton onClick={onDelete}>
        Delete
      </SecondaryButton>
      <DefaultButton onClick={onCancel}>
        Cancel
      </DefaultButton>
    </DialogActions>
  </WideDialog>
);

export default DeleteDialog;
