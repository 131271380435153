import React from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';
import { useMachine } from 'react-robot';

import { machine } from './ImporterMachine';
import ImportLink from './ImportLink';
import ImportDialog from './ImportDialog';
import getService from '../data/importing';
import { addRecords } from '../data/redux/thunks';

const Importer = ({ importActions }) => {
  const [current, send] = useMachine(machine, importActions);

  const showDialog = current.name !== 'picking'

  const selectFile = file => send({ type: 'pick', data: file });

  return (
    <React.Fragment>
      <ImportLink onFileSelected={selectFile} />
      {
        showDialog &&
        <ImportDialog current={current} send={current.service.send} />
      }
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  importActions: getService(flow([addRecords, dispatch])),
});

export default connect(null, mapDispatchToProps)(Importer);
