import React, { Fragment } from 'react';

import EditForm from './_EditForm';
import { FancyDateTime, Select, TextArea } from '../Forms'

class EditForm_TherapySession extends EditForm {

  constructor(props, context) {
    super(props, context, 'therapy-session');
  }

  state = {
    start: EditForm.getField('data.start')(this.props.record),
    type: EditForm.getField('data.type')(this.props.record),
    notes: EditForm.getField('data.notes')(this.props.record),
  }

  buildRecordData = () => {
    const { start, type, notes } = this.state;

    return {
      start: start || null,
      type,
      notes,
    };
  }

  renderTopicFields = () => {
    const { start, type, notes } = this.state;

    return (
      <Fragment>

        <FancyDateTime
          label="At"
          margin="normal"
          value={start}
          onChange={this.onChange('start')}
        />

        <Select
          label="Type"
          margin="normal"
          value={type || ''}
          onChange={this.onDomChange('type')}
        >
          <option value="">--Select--</option>
          <option>Physiotherapy</option>
        </Select>

        <TextArea
          label="Notes"
          margin="normal"
          rowsMax={8}
          value={notes || ''}
          onChange={this.onDomChange('notes')}
        />

      </Fragment>
    );
  }

}

export default EditForm_TherapySession;
