import React, { Component } from 'react';

import { buildReport } from '../data/buildReports';

const TICK_INTERVAL = 4000;

/**
 * Wrapper component that calculates and provides a report as a prop to its children.
 * Recalculates and pushes updates periodically.
 */
class ReportProvider extends Component {

  state = { dummy: null }

  triggerRender = () => {
    this.setState({ dummy: Date.now() });
  }

  componentDidMount() {
    this.intervalId = setInterval(this.triggerRender, TICK_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);    
  }

  render() {
    const { report: reportConfig, records, children } = this.props;

    const report = buildReport(reportConfig)(records);

    // Supply `report` prop to all direct children
    return React.Children.map(children, (child) => React.cloneElement(child, { report }));
  }
  
}

export default ReportProvider;
