import React from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const MenuButton = (props) => (
  <IconButton
    aria-label="Open menu"
    color="inherit"
    {...props}
  >
    <MenuIcon />
  </IconButton>
);

export const CloseMenuButton = (props) => (
  <IconButton
    aria-label="Close menu"
    color="inherit"
    {...props}
  >
    <ChevronLeftIcon />
  </IconButton>
);

export const DatabaseHeading = ({ selectedDatabase, ...props }) => (
  <Typography
    component="h1"
    variant="h5"
    color="inherit"
    noWrap
    {...props}
  >
    { selectedDatabase ? selectedDatabase.displayName : 'Baby Tracker' }
  </Typography>
);

export const DatabasesButton = (props) => (
  <IconButton
    aria-label="Show databases"
    color="inherit"
    {...props}
  >
    <KeyboardArrowDownIcon />
  </IconButton>
);
