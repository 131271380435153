import { isUndefined } from 'lodash/fp';

import { rawDuration } from './durations';

// Properties

export const isType = (topicId) => ({ type }) =>
  type === topicId;

export const dataPropIs = (prop) => (target) => ({ data }) =>
  data[prop] === target;

// Time

export const isMoment = ({ data: { start, end } }) =>
  isUndefined(end);

export const intersects = ({ since, until }) => ({ data: { start, end } }) => {
  const moment = isMoment({ data: { end }});

  return start && (
    (start >= since && (!until || start < until)) ||
    (start <= since && !moment && (!end || end >= since))
  );
};

export const isMainlyDay = ({ data: { start, end } }) =>
  !isMoment({ data: { start, end }}) ?
    isDurationAtDay({ start, end }) :
    isAtDay(start);

const isDurationAtDay = ({ start, end }) => {
  const middle = new Date(Math.floor((start.getTime() + end.getTime())/2));
  return isAtDay(middle);
};

const isAtDay = (d) => {
  const hour = d.getHours();
  return 7 <= hour && hour < 19;
};

export const getLatestDate = (until) => ({ data: { start, end } }) => {
  if (!start) return null;

  const moment = isMoment({ data: { end }});

  if (moment) {
    return start;
  } else {
    return end && end < until ?
      end :
      until;
  }
};

export const duration = ({ since, until }) => ({ data: { start, end } }={}) => {
  if (!start) return null;

  const startFrom = Math.max(start, since);
  const endAt = end ? Math.min(end, until) : until;

  return endAt - startFrom;
};

// Volume

export const volume = ({ since, until }) => (record={}) => {
  const { data: { start, end, volume: rawVolume } } = record;

  // Volume is apportioned if it extends outside target period
  const numericVolume = parseFloat(rawVolume);
  const applicableDuration = duration({ since, until })(record);
  const totalDuration = rawDuration({ start, end: end || until });

  const canCalculate =
    !isNaN(numericVolume) &&
    applicableDuration &&
    totalDuration;

  return canCalculate
    ? numericVolume * (applicableDuration / totalDuration)
    : null;
};
