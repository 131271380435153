import { createReducer } from 'redux-act';

import { databaseSelected, recordsLoaded, recordChanged, recordsChanged } from './actions';

const initState = null;

const recordReducer = (state, record) => {
  const next = { ...state, [record._id]: record };
  if (record._deleted) {
    delete next[record._id];
  }
  return next;
};

export default createReducer({
  [databaseSelected]: (_state, _database) => initState,
  [recordsLoaded]: (state, records) => records.reduce(recordReducer, state || {}),
  [recordsChanged]: (state, records) => records.reduce(recordReducer, state),
  [recordChanged]: recordReducer,
}, initState);
