import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MLink from '@material-ui/core/Link';

import WideDialog from './WideDialog';
import DatabaseSyncMessage from './DatabaseSyncMessage';

class SyncStatusDialog extends Component {
  render() {
    const { onClose, ...other } = this.props;

    return (
      <WideDialog
        onClose={onClose}
        {...other}
      >
        <DialogTitle>Sync status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DatabaseSyncMessage />
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <MLink
            variant='body2'
            component={Link}
            underline='none'
            to='/settings'
            onClick={onClose}
          >
            Configure your sync server URL
          </MLink>
        </DialogContent>
      </WideDialog>
    );
  }
};

export default SyncStatusDialog;
