import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
});

const TighterCardContent = ({ ...rest }) => (
  <CardContent
    { ...rest }
  />
);

export default withStyles(styles)(TighterCardContent);
