import React, { Component } from 'react';

import { buildReports } from '../data/buildReports';

const TICK_INTERVAL = 10000;

/**
 * Wrapper component that calculates and provides reports as a prop to its children.
 * Recalculates and pushes updates periodically.
 */
class ReportsProvider extends Component {

  state = { dummy: null }

  triggerRender = () => {
    this.setState({ dummy: Date.now() });
  }

  componentDidMount() {
    this.intervalId = setInterval(this.triggerRender, TICK_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);    
  }

  render() {
    const { reports: reportsConfig, records, children } = this.props;

    const reports = buildReports(reportsConfig)(records);

    // Supply `reports` prop to all direct children
    return React.Children.map(children, (child) => React.cloneElement(child, { reports }));
  }
  
}

export default ReportsProvider;
