import { Component } from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';

import { restartSync, logMessage } from '../data/redux/thunks';
import { withSelectors, currentSyncStatus } from '../data/selectors';

/**
 * A renderless component that is responsible for restarting
 * database sync in response to render events.
 */
class DatabaseSyncManager extends Component {

  constructor(props) {
    super(props);
    this.reconnectIntervalId = null;
  }

  restart = () => {
    const { restartSync } = this.props;

    restartSync();
  }

  restartIfDisconnected = () => {
    const { currentSyncStatus } = this.props;
    const isConnected = ['connected', 'active'].includes(currentSyncStatus.status);

    if (!isConnected) {
      this.restart();
    }
  }

  startInterval = () => {
    this.reconnectIntervalId = setInterval(this.restartIfDisconnected, 10 * 60 * 1000);
  }

  stopInterval = () => {
    clearInterval(this.reconnectIntervalId);
  }

  visibilityChanged = () => {
    const { logMessage } = this.props;

    const hidden = document.hidden;
    if (hidden) {
      logMessage('DatabaseSyncManager', 'App became hidden, ignoring that');
    } else {
      logMessage('DatabaseSyncManager', 'App became visible, restarting sync');
      this.restart();
    }
  }

  startTrackingVisibility = () => {
    document.addEventListener('visibilitychange', this.visibilityChanged, false);
  }

  stopTrackingVisibility = () => {
    document.removeEventListener('visibilitychange', this.visibilityChanged);    
  }

  componentDidMount() {
    this.startInterval();
    this.startTrackingVisibility();
  }

  componentWillUnmount() {
    this.stopInterval();
    this.stopTrackingVisibility();
  }

  render() {
    return null;
  }

};

const mapStateToProps = withSelectors({ currentSyncStatus });

export default flow([
  connect(mapStateToProps, { restartSync, logMessage }),
])(DatabaseSyncManager);
