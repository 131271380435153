import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import * as serviceWorker from './serviceWorker';
import App from './ui/App';

import domain from './domain';
import createStore from './ui/data/redux/createStore';
import { init } from './ui/data/redux/thunks';
import logger from './ui/data/logger';

const history = createBrowserHistory();

const store = createStore({ ...domain, logger, history });

store.dispatch(init());

const ui = (
  <Provider store={store}>
    <App history={history} />
  </Provider>
);
ReactDOM.render(ui, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// HACK: Try to hide browser addressbar chrome
window.scrollTo(0,1);
