import React, { Component } from 'react';
import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';

import { withSelectors, records, topics } from '../data/selectors';

import FloatingTopicAdd from '../components/FloatingTopicAdd';
import RecordGraph from '../components/RecordGraph';
import TopBar from '../components/TopBar';
import RecordsFilter from '../components/RecordsFilter';
import ToggleButton from '../components/ToggleButton';
import { Paragraph } from '../components/Typography';
import withLoading from '../components/withLoading';

const styles = theme => ({
  recordGraph: {
    marginBottom: theme.spacing(1),
  }
});

class Chart extends Component {

  state = {
    showFilter: false,
    filterTopics: false,
    topicsToShow: {},
  }

  toggleFilter = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  onFilterTopicsChanged = (filterTopics) => {
    this.setState({ filterTopics });
  }

  onTopicsToShowChanged = (topicId) => (show) => {
    const { topicsToShow } = this.state;
    topicsToShow[topicId] = show;
    this.setState({ topicsToShow });
  }

  render() {
    console.log('Chart', this.props);

    const { records, topics, classes } = this.props;
    const { showFilter, filterTopics, topicsToShow } = this.state;

    const filteredRecords = filterTopics ?
      records.filter(r => topicsToShow[r.type]) :
      records;

    return (
      <React.Fragment>

        <TopBar icon={<ToggleButton onClick={this.toggleFilter} toggled={showFilter}/>}>Chart</TopBar>

        <Collapse in={showFilter}>
          <RecordsFilter
            withGaps={false}
            onShowGapsChanged={this.onShowGapsChanged}
            filterTopics={filterTopics}
            onFilterTopicsChanged={this.onFilterTopicsChanged}
            topicsToShow={topicsToShow}
            onTopicsToShowChanged={this.onTopicsToShowChanged}
            topics={topics}
          />
        </Collapse>

        <RecordGraph
          className={classes.recordGraph}
          records={filteredRecords}
          topics={topics}
        />

        {
          filteredRecords.length === 0 &&
          (filterTopics ?
            <Paragraph>No matching results</Paragraph> :
            <Paragraph>No records yet</Paragraph>
          )
        }

        <FloatingTopicAdd />

      </React.Fragment>
    );
  }
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default flow([
  withLoading(props => props.records === null),
  withStyles(styles),
  connect(withSelectors({ records, topics })),
])(Chart);
