import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { DefaultButton } from '../components/Forms';
import { withFile } from '../components/domUtilities';

const ImportLink = ({ classes, onFileSelected }) => (
  <label>
    <input
      type="file"
      accept=".json,.csv"
      className={classes.input}
      onChange={withFile(file => file && onFileSelected(file))}
      onClick={e => { e.target.value = null }}
    />
    <DefaultButton component="span">
      Import new records
      <CloudUploadIcon className={classes.rightIcon} />
    </DefaultButton>
  </label>
);

ImportLink.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileSelected: PropTypes.func.isRequired,
};

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});

export default withStyles(styles)(ImportLink);
