import { isDate, isString, has, get, set, map, cloneDeep } from 'lodash/fp';

const DefaultDateFields = [
  'data.start',
  'data.end',
];

/** A domain service decorator that converts dates. */
class DateConvertingDomainService {

  constructor(wrappedService, dateFieldPaths=DefaultDateFields) {
    this.wrappedService = wrappedService;
    this.dateFieldPaths = dateFieldPaths;
  }

  get onChange() {
    if (!this._onChange) {
      // Decorate wrapped `onChange#add` to perform transformation
      const proto = this.wrappedService.onChange;
      const add = (listener, ...rest) => {
        const transform = unwrapDateFields(this.dateFieldPaths);

        // NB. Do not use an arrow function here, since signals
        // support binding context for the listeners
        function newListener(rawRecord, ...rest) {
          return listener(transform(rawRecord), ...rest);
        }

        return proto.add(newListener, ...rest);
      };
      this._onChange = Object.assign(Object.create(proto), { add });
    }

    return this._onChange;
  }

  unsubscribe(subscription) {
    return this.wrappedService.unsubscribe(subscription);
  }

  getRecords() {
    return this.wrappedService
      .getRecords()
      .then(map(unwrapDateFields(this.dateFieldPaths)));
  }

  addRecord(record) {
    const convertedRecord = wrapDateFields(this.dateFieldPaths)(record);
    return this.wrappedService.addRecord(convertedRecord);
  }

  addRecords(records) {
    const convertedRecords = records.map(wrapDateFields(this.dateFieldPaths));
    return this.wrappedService.addRecords(convertedRecords);
  }

  updateRecord(record) {
    const convertedRecord = wrapDateFields(this.dateFieldPaths)(record);
    return this.wrappedService.updateRecord(convertedRecord);
  }

  deleteRecord(record) {
    const convertedRecord = wrapDateFields(this.dateFieldPaths)(record);
    return this.wrappedService.deleteRecord(convertedRecord);
  }

  getTopics() {
    return this.wrappedService.getTopics();
  }

}

export default DateConvertingDomainService;

// Implementation

const processDateFields = (transform) => (fields) => (record) => {
  let copy = cloneDeep(record);

  fields.forEach(field => {
    if (has(field, copy)) {
      const oldValue = get(field, copy);
      const newValue = transform(oldValue);
      copy = set(field, newValue, copy);
    }
  });

  return copy;
};

const wrap = (value) =>
  isDate(value) ?
    value.toISOString() :
    value;

const unwrap = (value) =>
  isISODateString(value) ?
    new Date(value) :
    value;

const ISODateRegExp = /^([0-9]{4})-([0-9]{2})-([0-9]{2})([0-9:T.Z]*)$/;

const isISODateString = (value) =>
  isString(value) &&
  ISODateRegExp.test(value);

/** Wrap given date fields in a record. */
export const wrapDateFields = processDateFields(wrap);

/** Unwrap given date fields in a record. */
export const unwrapDateFields = processDateFields(unwrap);
