import BottleFeed from './BottleFeed';
import BreastFeed from './BreastFeed';
import Contraction from './Contraction';
import NappyChange from './NappyChange';
import Note from './Note';
import Sleep from './Sleep';
import Wash from './Wash';
import Food from './Food';
import PopIn from './PopIn';
import TherapySession from './TherapySession';
import Measurement from './Measurement';
import Cry from './Cry';

import Unsupported from './Unsupported';

const map = {
  'bottle-feed': BottleFeed,
  'breast-feed': BreastFeed,
  'contraction': Contraction,
  'nappy-change': NappyChange,
  'note': Note,
  'sleep': Sleep,
  'wash': Wash,
  'food': Food,
  'pop-in': PopIn,
  'therapy-session': TherapySession,
  'measurement': Measurement,
  'cry': Cry,
};

const forTopic = (topicId) =>
  map[topicId] || Unsupported;

export default {
  forTopic,
};
