import { rawDuration, formatDuration } from '../data/durations';

export default ({ start, end, duration, thresholdMs, thresholdContent }) => {
  const durationInMs = rawDuration({ start, end, duration });

  const message = formatDuration(durationInMs);

  const isOverThreshold =
    thresholdMs &&
    thresholdContent &&
    (durationInMs >= thresholdMs);

  return isOverThreshold ? thresholdContent : message;
};
