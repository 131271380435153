import Papa from 'papaparse';
import { get, map } from 'lodash/fp';

import { abortedError } from './errors';
import { pickTruthyKeys } from './utilities';

const rows = result => get('data', result);
const errors = result => get('errors', result);
const isAborted = result => get('meta.aborted', result);
const ignoredErrorTypes = [ 'UndetectableDelimiter' ];
const isUnsafeError = error => !ignoredErrorTypes.includes(get('code', error));

const parseCsv = (file) =>
  parseToRows(file).then(map(structure));

const parseToRows = (file) =>
  new Promise((resolve, reject) => {
    const onComplete = (results, _file) => {
      const problems = [
        ...errors(results),
        isAborted(results) && abortedError(),
      ].filter(Boolean).filter(isUnsafeError);

      return problems.length > 0
        ? reject(problems)
        : resolve(rows(results));
    };

    const onError = (results, _file) => {
      const problems = errors(results).filter(isUnsafeError);
      return reject(problems);
    };

    Papa.parse(file, {
      // ---- header options ----
      header: true,
      transformHeader: header => header.toLowerCase(),
      // ---- data options ----
      skipEmptyLines: 'greedy', // skip lines with no non-whitespace chars
      dynamicTyping: false,     // treat values as strings
      // ---- handlers ----
      complete: onComplete,
      error: onError,
    });
  });

const structure = ({ type, ...rest }) => pickTruthyKeys({
  type,
  data: pickTruthyKeys(dataFor(type, rest)),
});

const dataFor = (type, rest) =>
  (mapperByType[type] || defaultMapper)(rest);

const defaultMapper = ({ subtype, ...rest }) => ({ type: subtype, ...rest });

const mapperByType = {
  'breast-feed': ({ subtype, ...rest }) => ({ side: subtype, ...rest }),
  'contraction': ({ subtype, ...rest }) => ({ discomfort: subtype, ...rest }),
  'bottle-feed': ({ subtype, quantity, ...rest }) => ({ type: subtype, volume: quantity, ...rest }),
};

export default parseCsv;
