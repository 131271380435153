import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import WideDialog from './WideDialog';
import { DefaultButton, PrimaryButton, SecondaryButton } from './Forms';

const ImportDialog = ({ current, send, ...rest }) => {
  const { name, context } = current;

  const StateComponent = {
    'idle': Idle,
    'parsing': Parsing,
    'transforming': Transforming,
    'previewing': Previewing,
    'importing': Importing,
    'summary': Summary,
    'error': Error,
  }[name] || UnknownState;

  return (
    <WideDialog {...rest} open={true} onClose={() => send('finished')}>
      <DialogTitle>Import records</DialogTitle>
      <DialogContent>
        <StateComponent send={send} data={context} />
      </DialogContent>
    </WideDialog>
  );
};

// Individual state UI

const Idle = ({ data, send }) => (
  <>
    <DialogContentText>
      Analyse '{ data.file.name }'?
    </DialogContentText>
    <DialogContentText>
      (You'll have the option to import afterwards)
    </DialogContentText>
    <DialogActions>
      <PrimaryButton onClick={() => send('convert')}>
        Analyse
      </PrimaryButton>
      <DefaultButton onClick={() => send('finished')}>
        Cancel
      </DefaultButton>
    </DialogActions>
  </>
);

const Parsing = ({ data }) => (
  <DialogContentText>
    Parsing file to records...
  </DialogContentText>
);

const Transforming = ({ data }) => (
  <DialogContentText>
    Transforming records to BabyTracker style...
  </DialogContentText>
);

const Previewing = ({ data, send }) => (
  <>
    <DialogContentText>
      Import {data.pendingRecords.length} record(s)?
    </DialogContentText>
    <DialogActions>
      <SecondaryButton onClick={() => send('import')}>
        Import
      </SecondaryButton>
      <DefaultButton onClick={() => send('finished')}>
        Cancel
      </DefaultButton>
    </DialogActions>
  </>
);

const Importing = () => (
  <DialogContentText>
    Importing...
  </DialogContentText>
);

const Summary = ({ data, send }) => {
  const importErrors = data.results.filter(r => r.error);
  return (
    <>
      <DialogContentText>
        Imported {data.results.length} records!
      </DialogContentText>
      { importErrors.length > 0 &&
        <>
          <DialogContentText>
            Found {importErrors.length} errors:
          </DialogContentText>
          <List dense={true}>
            {
              importErrors.map((importError, i) => (
                <ListItem key={i} disableGutters={true} divider={true}>
                  <ListItemText>{ importError.message }</ListItemText>
                </ListItem>
              ))
            }
          </List>
        </>
      }
      <DialogActions>
        <DefaultButton onClick={() => send('finished')}>
          Finished
        </DefaultButton>
      </DialogActions>
    </>
  );
};

const Error = ({ data, send }) => (
  <>
    <DialogContentText>
      Oh no! Found {data.errors.length} error(s):
    </DialogContentText>
    <List dense={true}>
      {
        data.errors.map((error, i) => (
          <ListItem key={i} disableGutters={true} divider={true}>
            <ListItemText>
              <FriendlyError error={error} />
            </ListItemText>
          </ListItem>
        ))
      }
    </List>
    <DialogActions>
      <DefaultButton onClick={() => send('finished')}>
        Close
      </DefaultButton>
    </DialogActions>
  </>
);

const FriendlyError = ({ error }) => {
  const idSortOfThing = `${error.type}:${error.code}`
  return (
    <>
      { (errorTypeCodeMsg[idSortOfThing] || defaultErrorMsg)(error) }
      <ErrorCode>[{ idSortOfThing }]</ErrorCode>
    </>
  );
};
const ErrorCode = (props) => (
  <span
    style={{ fontSize: '80%', opacity: '0.7', padding: '0 5px' }}
    {...props}
  />
);

const errorTypeCodeMsg = {
  'BabyTracker:RecordError': error => `Record #${error.rowIndex}: ${error.message}`,
}
const defaultErrorMsg = error => `${error.message}`;

const UnknownState = ({ send }) => (
  <>
    <DialogContentText>Oh dear, don't know how we got here.</DialogContentText>
    <DialogActions>
      <DefaultButton onClick={() => send('finished')}>
        Close
      </DefaultButton>
    </DialogActions>
  </>
);

export default ImportDialog;
