import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MLink from '@material-ui/core/Link';

import WideDialog from './WideDialog';
import TopicIcon from './TopicIcon';
import TopicQuickAdd from './TopicQuickAdd';

class AddTopicDialog extends Component {

  handleClose = () => {
    this.props.onClose();
  };

  handleListItemClick = (topic) => () => {
    this.props.onClose(topic);
  };

  render() {
    const { topics, onClose, onStart, ...other } = this.props;

    return (
      <WideDialog onClose={this.handleClose} {...other}>
        <DialogTitle>Add a...</DialogTitle>
        <DialogContent>
          <List>
          {
            (topics || []).map(topic => (
              <ListItem
                key={topic.id}
                button
                onClick={this.handleListItemClick(topic)}
              >
                <ListItemIcon><TopicIcon topicId={ topic.id }/></ListItemIcon>
                <ListItemText>{ topic.name }</ListItemText>
                <ListItemSecondaryAction>
                  <TopicQuickAdd topic={topic} onStart={onStart} />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
          </List>
          <MLink
            variant='body2'
            component={Link}
            underline='none'
            to='/settings'
            onClick={() => onClose()}
          >
            Customise this list
          </MLink>
        </DialogContent>
      </WideDialog>
    );
  }
};

export default AddTopicDialog;
