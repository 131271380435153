import React from 'react';
import { flow } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';

import { withSelectors, selectedDatabase } from '../data/selectors';
import { viewRecords } from '../data/redux/thunks';

import MenuItems from './Layout.menuItems';
import {
  MenuButton,
  CloseMenuButton,
  DatabaseHeading,
} from './Layout.components';
import SyncStatusDialogManager from './SyncStatusDialogManager';
import DatabasesDialogManager from './DatabasesDialogManager';
import DatabaseSyncTrigger from './DatabaseSyncTrigger';

import 'typeface-roboto';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },

  toolbar: {
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  iconButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  iconButtonHidden: {
    display: 'none',
  },

  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  titleText: {
    margin: 'auto 0', // Vertically centre
  },

  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },

  appBarSpacer: theme.mixins.toolbar,
  
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    height: '100vh',
    overflow: 'auto',
  },
});

class Layout extends React.Component {
  state = {
    open: false,
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { classes, children, selectedDatabase, viewRecords } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>

        <CssBaseline />

        <div className={classes.root}>
          {/* Header bar */}
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar
              disableGutters={!open}
              className={classes.toolbar}
            >
              <MenuButton
                onClick={this.handleDrawerOpen}
                className={classNames(classes.iconButton, open && classes.iconButtonHidden)}
              />

	      <DatabaseSyncTrigger
                className={classes.title}
	      >
		<DatabaseHeading
		  className={classes.titleText}
		  onClick={viewRecords}
		  selectedDatabase={selectedDatabase}
		/>
		<DatabasesDialogManager
		  className={classes.iconButton}
		/>
	      </DatabaseSyncTrigger>

              <SyncStatusDialogManager
                className={classes.iconButton}
              />
            </Toolbar>
          </AppBar>

          {/* Side menu */}
          <Drawer
            classes={{
              paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={this.state.open}
            onClick={this.handleDrawerClose}
          >
            <div className={classes.toolbarIcon}>
              <CloseMenuButton onClick={this.handleDrawerClose} />
            </div>

            <Divider />
            
            <MenuItems selectedDatabase={selectedDatabase} />
          </Drawer>

          {/* Content */}
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            { children }
          </main>
        </div>
        
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = withSelectors({ selectedDatabase });

export default flow([
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, { viewRecords }),
])(Layout);
