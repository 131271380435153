import React from 'react';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';

const styles = theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
});

const SpacedCard = ({ classes, className, ...rest }) => (
  <Card
    className={classnames(classes.card, className)}
    {...rest}
  />
);

export default withStyles(styles)(SpacedCard);
