import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import TopicIcon from '../TopicIcon';

export default (props) => (
  <ListItem {...props}>
    <ListItemIcon>
      <TopicIcon />
    </ListItemIcon>
    <ListItemText>Unknown record type</ListItemText>
  </ListItem>
);
