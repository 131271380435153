import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import DeleteDialog from './DeleteDialog'
import { DefaultButton, PrimaryButton } from './Forms'

const styles = theme => ({
  buttons: {
    marginTop: theme.spacing(2),
    minHeight: '56px',
  },
});

class EditFormBase extends Component {

  state = {
    showDialog: false,
  }

  toggleDialog = () => {
    this.setState({ showDialog: !this.state.showDialog });
  }

  render() {
    const {
      onSave,
      onCancel,
      onDelete,
      isValid,
      saveLabel="Update",
      cancelLabel="Cancel",
      dialog: Dialog=DeleteDialog,
      children,
      classes,
    } = this.props;

    const { showDialog } = this.state;

    return (
      <FormControl component="fieldset" fullWidth={true}>

        { children }

        <Grid container
          spacing={2}
          justify="center"
          alignItems="center"
          wrap="nowrap"
          className={classes.buttons}
        >
          <Grid item>
            <PrimaryButton onClick={onSave} disabled={!isValid}>
              { saveLabel }
            </PrimaryButton>
          </Grid>
          <Grid item>
            <DefaultButton onClick={onCancel}>
              { cancelLabel }
            </DefaultButton>
          </Grid>
          <Grid item>
            <IconButton onClick={this.toggleDialog} disabled={!onDelete}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Dialog open={showDialog} onDelete={onDelete} onCancel={this.toggleDialog} />

      </FormControl>
    );
  }
}


export default withStyles(styles)(EditFormBase);
