import Signal from 'signals';
import { set } from 'lodash/fp';

const DefaultSettings = {};

export const DOCUMENT_ID = '_local/settings';

class PouchDbSettingsService {

  constructor(db) {
    this.db = db;
    this.documentId = DOCUMENT_ID;
    this._onChange = new Signal();
  }

  _getSettingsDoc() {
    return this.db
      .get(this.documentId)
      .catch(err => {
        console.warn(err);
        return this._getDefaultDoc();
      });
  }

  _getDefaultDoc() {
    return {
      _id: this.documentId,
      settings: DefaultSettings,
    };
  }

  // Interface

  get onChange() {
    return this._onChange;
  }

  /** Unsubscribe a subscription obtained from `this.onChange.add(fn)` */
  unsubscribe(subscription) {
    // HACK: We know the subscriptions are of type SignalContext
    subscription.detach();
  }

  getSettings() {
    return this._getSettingsDoc()
      .then(doc => doc.settings);
  }

  updateSettings(settings) {
    return this._getSettingsDoc()
      .then(set('settings', settings))
      .then(doc => this.db.put(doc))
      .then(() => this._onChange.dispatch())
      .catch(console.error);
  }

}

export default PouchDbSettingsService;
