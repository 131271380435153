import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Update';
import ClearIcon from '@material-ui/icons/Clear';

import { parse, toFormat } from '../data/dates';

export { default as ChipSelect } from './ChipSelect'

/**
 * A thin adapter over a `datetime-local` `TextField` that marshals
 * date values to and from the textual value used in the native `input`.
 */
class DateTime extends Component {

  onChange = (e) => {
    const dateString = e.target.value;
    const date = dateString ? parse(dateString) : null;
    this.props.onChange(date);
  }

  render() {
    const { InputLabelProps, inputProps, value, onChange, ...rest } = this.props;

    return (
      <TextField
        type="datetime-local"
        InputLabelProps={{ ...InputLabelProps, shrink: true }}
        inputProps={{ ...inputProps, step: 1 }}
        value={value ? toFormat('YYYY-MM-DDTHH:mm:ss')(value) : ''}
        onChange={this.onChange}
        { ...rest }
      />
    );
  }
}

/**
 * A decorated DateTime input that has a button to initialise the value to now.
 */
class FancyDateTime extends Component {
  initialiseValueToNow = (_e) => {
    const { onChange } = this.props;
    onChange(new Date());
  }

  clearValue = (_e) => {
    const { onChange } = this.props;
    onChange(null);
  }

  render() {
    const { value, InputProps, ...rest } = this.props;

    const clearButton = (
      <IconButton
        aria-label="Clear selected date and time"
        color="primary"
        onClick={this.clearValue}
      >
        <ClearIcon style={{ fontSize: '32px' }} />
      </IconButton>
    )
    const nowButton = (
      <IconButton
        aria-label="Select current date and time"
        color="primary"
        onClick={this.initialiseValueToNow}
      >
        <UpdateIcon style={{ fontSize: '32px' }} />
      </IconButton>
    )

    const endAdornment = (
      <InputAdornment position="end">
        { value ? clearButton : nowButton }
      </InputAdornment>
    )

    return (
      <DateTime
        value={value}
        InputProps={{ ...InputProps, endAdornment }}
        { ...rest }
      />
    );
  }
}

const Select = ({ InputLabelProps, SelectProps, ...rest }) => (
  <TextField
    select={true}
    InputLabelProps={{ ...InputLabelProps, shrink: true }}
    SelectProps={{ ...SelectProps, native: true }}
    { ...rest }
  />
);

const TextArea = ({ ...rest }) => (
  <TextField
    variant="filled"
    multiline={true}
    { ...rest }
  />
);

const Text = ({ ...rest }) => (
  <TextField
     { ...rest }
  />
);

const NumberField = ({ ...rest }) => (
  <TextField
    type="number"
    {...rest}
  />
);

const DefaultButton = (props) => (
  <Button
    variant="contained"
    { ...props }
  />
);

const TextButton = (props) => (
  <Button
    variant="text"
    { ...props }
  />
);

const PrimaryButton = (props) => (
  <DefaultButton
    color="primary"
    { ...props }
  />
);

const SecondaryButton = (props) => (
  <DefaultButton
    color="secondary"
    { ...props }
  />
);

export {
  DateTime,
  FancyDateTime,
  Select,
  Text,
  NumberField,
  TextArea,
  DefaultButton,
  TextButton,
  PrimaryButton,
  SecondaryButton,
};
