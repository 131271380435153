import React, { Component, Fragment } from 'react';
import { flow } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import reports from '../data/reports';
import { withSelectors, records } from '../data/selectors';
import { viewReport } from '../data/redux/thunks';

import ReportsProvider from '../components/ReportsProvider';
import ReportSummary from '../components/ReportSummary';
import TopBar from '../components/TopBar';
import withLoading from '../components/withLoading';
import FloatingTopicAdd from '../components/FloatingTopicAdd';

class Reports extends Component {
  render() {
    console.log('Dashboard', this.props);

    const { records, viewReport } = this.props;

    return (
      <Fragment>

        <TopBar>Dashboard</TopBar>

        <ReportsProvider reports={reports} records={records}>
          <ReportSummaries onSelect={viewReport} />
        </ReportsProvider>

        <FloatingTopicAdd />

      </Fragment>
    );
  }
}

// Simple component used to receive the `reports` prop
const ReportSummaries = ({ reports, ...rest }) => (
  <Fragment>
  {
    (reports || []).map(report => (
      <ReportSummary key={report.id} report={report} {...rest} />
    ))
  }
  </Fragment>
);

export default flow([
  withLoading(props => props.records === null),
  connect(withSelectors({ records }), { viewReport }),
  withRouter,
])(Reports);
