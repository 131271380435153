import { errors as findErrors } from './validation';
import { argumentError, recordError } from './errors';

const toDate = (value) => {
  const date = new Date(value);
  if (!date || isNaN(date.valueOf())) {
    throw new Error(`Failed to parse "${value}" as a date`);
  }
  return date;
};

const toRecord = (row) => {
  if (!row) {
    throw new Error('Row can not be null');
  }

  const record = {
    type: row.type,
    data: { ...row.data },
  };

  if ('start' in record.data) {
    record.data.start = toDate(row.data.start);
  }
  if ('end' in record.data) {
    record.data.end = toDate(row.data.end);
  }

  return record;
};

const convert = (rows) => {
  if (!rows) {
    return Promise.reject([argumentError('Must supply rows to convert')])
  }

  const records = [];
  const errors = [];

  rows.forEach((row, i) => {
    try {
      const record = toRecord(row);

      const validationErrors = findErrors(record);

      if (validationErrors.length > 0) {
        validationErrors.forEach(err => {
          errors.push(recordError(i, err))
        })
      } else {
        records.push(record);
      }
    } catch(error) {
      errors.push(recordError(i, error));
    }
  });

  return errors.length > 0
    ? Promise.reject(errors)
    : Promise.resolve(records);
};

export default convert;
