import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { merge } from '../data/topics';
import TopicIcon from './TopicIcon';
import { TextButton } from './Forms';
import { upOne, downOne, show, hide } from './TopicsPicker.utils';

const TopicsPicker = ({
  label,
  helperText,
  topics,
  order: _order,
  onOrderChange,
  hidden: _hidden,
  onHiddenChange,
  ...rest
}) => {
  const order = _order || [];
  const hidden = _hidden || {};

  const topicIds = topics.map(item => item.id);

  const orderedIds = merge(topicIds, order);

  const buildProps = (item, index, items) => ({
    ...item,
    visible: !hidden[item.id],
    moveUp: index > 0 ? () => onOrderChange(upOne(orderedIds, index)) : null,
    moveDown: index < items.length - 1 ? () => onOrderChange(downOne(orderedIds, index)) : null,
    show: () => onHiddenChange(show(hidden, item.id)),
    hide: () => onHiddenChange(hide(hidden, item.id)),
  })

  return (
    <FormControl {...rest}>
      <FormLabel>{ label }</FormLabel>
      <FormHelperText>{ helperText }</FormHelperText>
      <List dense>
        {
          orderedIds
            .map(id => topics.find(item => item.id === id))
            .filter(Boolean)
            .map(buildProps)
            .map(props => <Item key={props.id} {...props} />)
        }
      </List>
      <TextButton size="small" onClick={() => onOrderChange(topicIds)}>
        Use default record order
      </TextButton>
    </FormControl>
  );
}

const Item = ({ id, name, visible, moveUp, moveDown, hide, show }) => (
  <TopicListItem id={id} name={name}>
    <SmallIconButton onClick={moveUp} disabled={!moveUp}>
      <ArrowUpwardIcon fontSize="small" />
    </SmallIconButton>
    <SmallIconButton onClick={moveDown} disabled={!moveDown}>
      <ArrowDownwardIcon fontSize="small" />
    </SmallIconButton>
    {
      visible ? (
        <SmallIconButton onClick={hide}>
          <VisibilityIcon fontSize="small" />
        </SmallIconButton>
      ) : (
        <SmallIconButton onClick={show}>
          <VisibilityOffIcon fontSize="small" />
        </SmallIconButton>
      )
    }
  </TopicListItem>
)

const TopicListItem = ({ id, name, children }) => (
  <ListItem dense>
    <ListItemIcon>
      <TopicIcon topicId={ id } fontSize="small" />
    </ListItemIcon>
    <ListItemText primary={name} />
    <ListItemSecondaryAction>
      { children }
    </ListItemSecondaryAction>
  </ListItem>
)

const SmallIconButton = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(IconButton);

export default TopicsPicker;
