import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';

import BaseRecordItem, { TimeInfo, Notes } from './_BaseRecordItem';

export default ({ record, topic, showNotes, ...rest }) => {
  const { start, end, side, notes } = record.data;

  const blurb = (
    <Fragment>
      <Grid container component="span" justify="space-between">
        <Grid item component="span">
          <TimeInfo start={start} end={end} />
        </Grid>
        <Grid item component="span">
          { side }
          { !showNotes && notes && '*' }
        </Grid>
      </Grid>
      { showNotes && <Notes notes={notes} /> }
    </Fragment>
  );

  return (
    <BaseRecordItem topic={topic} blurb={blurb} active={start && !end} {...rest} />
  );
}
