import { isString, isDate, isFunction } from 'lodash/fp';

// Predicates

const validTypes = [
  'breast-feed',
  'bottle-feed',
  'nappy-change',
  'sleep',
  'pop-in',
  'note',
  'wash',
  'food',
  'contraction',
  'therapy-session',
  'measurement',
];

const notNull = example => !!example;
const hasRecordType = example => 'type' in example;
const hasRecordData = example => 'data' in example;
const hasValidRecordType = example => validTypes.includes(example.type)

const isNumericString = value =>
  isString(value) && !isNaN(parseFloat(value))

const hasDataField = field => example => field in example.data;
const validIfPresent = (field, test) => (example) =>
  !hasDataField(field)(example) || test(example.data[field]);

const hasValidStart      = validIfPresent('start', isDate);
const hasValidNotes      = validIfPresent('notes', isString);
const hasValidEnd        = validIfPresent('end', isDate);
const hasValidVolume     = validIfPresent('volume', isNumericString);
const hasValidQuantity   = validIfPresent('quantity', isNumericString);
const hasValidType       = validIfPresent('type', isString);
const hasValidDiscomfort = validIfPresent('discomfort', isString);
const hasValidSide       = validIfPresent('side', isString);

// Results

const pass = Symbol('pass');
const isPass = (result) => result === pass;

const isFail = (result) => !isPass(result);
const fail = (msg) => msg;

// Rules

const rule = (predicate, msg) => (example) =>
  predicate(example)
    ? pass
    : fail(isFunction(msg) ? msg(example) : msg);

const ruleGroups = [
  // Always:
  [
    rule(notNull, 'Supplied record must be an object'),
  ],
  // Only if present:
  [
    rule(hasRecordType, 'Missing "type" property'),
    rule(hasRecordData, 'Missing "data" property'),
  ],
  // Only if sane:
  [
    rule(hasValidRecordType, example => `Invalid type "${example.type}"`),
  ],
  // Field type checks:
  [
    rule(hasValidStart, 'Start field can only be a date'),
    rule(hasValidNotes, 'Notes field can only be text'),
    rule(hasValidEnd, 'End field can only be a date'),
    rule(hasValidVolume, 'Volume field can only be numeric text'),
    rule(hasValidQuantity, 'Quantity field can only be numeric text'),
    rule(hasValidType, 'Type field can only be text'),
    rule(hasValidDiscomfort, 'Discomfort field can only be text'),
    rule(hasValidSide, 'Side field can only be text'),
  ],
  // Required fields:
  [
    rule(hasDataField('start'), 'Must have a start date'),
  ],
];

// Validator

const applyRule = (example) => (rule) => rule(example);
const applyRules = (example) => (rules) => rules.map(applyRule(example));
const errorsForRules = (example) => (rules) => applyRules(example)(rules).filter(isFail);
const stopOnErrors = (example) => (errs, rules) =>
  errs.length > 0 ? errs : errorsForRules(example)(rules);

export const errors = (example) =>
  ruleGroups.reduce(stopOnErrors(example), []);
