import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import ViewListIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightIcon from '@material-ui/icons/Highlight';
import InfoIcon from '@material-ui/icons/Info';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

import { Link, Route } from 'react-router-dom';

/**
 * A ListItem variant that sets the `selected` based on the currently matched route.
 */
const NavListItem = ({children, to, ...rest}) => (
  <Route path={to} children={({ match }) => (
    // Route children function will be passed null match for non-matching route
    <ListItem to={to} selected={!!match} {...rest}>
      { children }
    </ListItem>
  )}/>
);

const MenuItemContent = ({iconComponent: IconComponent, text }) => (
  <Fragment>
    <ListItemIcon>
      <IconComponent />
    </ListItemIcon>
    <ListItemText>{ text }</ListItemText>
  </Fragment>
);

const MenuSectionHeader = withStyles(theme => ({
  header: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '120%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: '120%',
    marginRight: theme.spacing(1),
  },
  text: {
    color: 'inherit',
    flex: '0 1 40%',
  },
}))(({ classes, iconComponent: IconComponent, text }) => (
  <ListSubheader className={classes.header}>
    <IconComponent className={classes.icon} />
    <Typography className={classes.text}>{ text }</Typography>
  </ListSubheader>
))

const MenuItems = ({ selectedDatabase }) => (
  <Fragment>
    { selectedDatabase && 
      <List
        subheader={
          <MenuSectionHeader iconComponent={ChildCareIcon} text={selectedDatabase.displayName} />
        }
        style={{ paddingTop: '8px' }}
      >
        <NavListItem component={Link} button to='/records'>
          <MenuItemContent iconComponent={ViewListIcon} text='Records' />
        </NavListItem>
        <NavListItem component={Link} button to='/reports'>
          <MenuItemContent iconComponent={HighlightIcon} text='Dashboard' />
        </NavListItem>
        <NavListItem component={Link} button to='/graph'>
          <MenuItemContent iconComponent={InsertChartIcon} text='Chart' />
        </NavListItem>
        <NavListItem component={Link} button to='/sleep-trends'>
          <MenuItemContent iconComponent={TrendingUpIcon} text='Sleep Trends' />
        </NavListItem>
        <NavListItem component={Link} button to='/measurement-charts'>
          <MenuItemContent iconComponent={MultilineChartIcon} text='Measurements' />
        </NavListItem>
        <NavListItem component={Link} button to='/settings'>
          <MenuItemContent iconComponent={SettingsIcon} text='Settings' />
        </NavListItem>
        <NavListItem component={Link} button to='/data'>
          <MenuItemContent iconComponent={FileCopyIcon} text='Data' />
        </NavListItem>
      </List>
    }
    { selectedDatabase &&
      <Divider />
    }
    <List
      subheader={
        <MenuSectionHeader iconComponent={SmartphoneIcon} text="App" />
      }
      style={{ paddingTop: '8px' }}
    >
      <NavListItem component={Link} button to='/about'>
        <MenuItemContent iconComponent={InfoIcon} text='About' />
      </NavListItem>
    </List>
  </Fragment>
);

export default MenuItems;
