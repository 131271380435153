import React from 'react';

import {
  FaBed,
  FaComment,
  FaPoo,
  FaQuestion,
  FaStopwatch,
  FaUtensils,
  FaPrescriptionBottle,
  FaBath,
  FaFemale,
  FaDoorOpen,
  FaHandsHelping,
  FaRuler,
  FaSadTear,
} from 'react-icons/fa';

const Icons = {
  'breast-feed': FaFemale,
  'bottle-feed': FaPrescriptionBottle,
  'contraction': FaStopwatch,
  'food': FaUtensils,
  'nappy-change': FaPoo,
  'note': FaComment,
  'sleep': FaBed,
  'wash': FaBath,
  'pop-in': FaDoorOpen,
  'therapy-session': FaHandsHelping,
  'measurement': FaRuler,
  'cry': FaSadTear,
};

const Unsupported = FaQuestion;

const TopicIcon = ({ topicId, style, ...rest }) => {
  const Icon = Icons[topicId] || Unsupported;
  return <Icon style={{ ...style, color: 'rgba(0,0,0,0.75)' }} { ...rest } />;
};

export default TopicIcon;
