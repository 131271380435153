import { combineReducers } from 'redux';

import selectedDatabaseId from './reducer.selectedDatabaseId';
import selectedRecord from './reducer.selectedRecord';
import databases from './reducer.databases';
import recordsById from './reducer.recordsById';
import settings from './reducer.settings';
import topics from './reducer.topics';
import sync from './reducer.sync';

export default combineReducers({
  selectedDatabaseId,
  selectedRecord,
  databases,
  recordsById,
  settings,
  topics,
  sync,
});
