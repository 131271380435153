export const upOne = (list, index) =>
  0 <= index-1 && index < list.length
    ? [
      ...list.slice(0, index - 1),
      list[index],
      list[index - 1],
      ...list.slice(index + 1),
    ]
    : list;

export const downOne = (list, index) =>
  0 <= index && index+1 < list.length
    ? [
      ...list.slice(0, index),
      list[index + 1],
      list[index],
      ...list.slice(index + 2),
    ]
    : list;

export const show = (map, id) => {
  const copy = clone(map);
  delete copy[id];
  return copy;
};

export const hide = (map, id) => {
  const copy = clone(map);
  copy[id] = true;
  return copy;
};

const clone = (map) => ({ ...map });
