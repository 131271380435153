import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import RecordItems from './RecordItems';
import EventGapItem from './EventGapItem';

class RecordList extends Component {

  onSelect = (record) => () => {
    this.props.onSelect(record);
  }

  onStop = (record) => () => {
    this.props.onStop(record);
  }

  render() {
    const { records, topics, showGaps, showNotes, className } = this.props;

    return (
      <Card className={className}>
        <List disablePadding={true} dense>
          {
            records.reduce((acc, record, i) => {
              const topic = topics.find(t => t.id === record.type);
              const RecordItem = RecordItems.forTopic(record.type);

              const gap = showGaps ? (
                <ListItem divider key={`gap:${record._id}`}>
                  <EventGapItem until={records[i-1]} since={record} />
                </ListItem>
              ) : null;

              const item = (
                <RecordItem
                  divider
                  button
                  key={record._id}
                  onClick={ this.onSelect(record) }
                  onStop={ this.onStop(record) }
                  record={record}
                  topic={topic}
                  showNotes={showNotes}
                />
              );

              return acc.concat(gap, item);
            }, [])
          }
        </List>
      </Card>
    );
  }

}

export default RecordList;
