import SyncManager from './SyncManager';
import PouchDbDomainService from './PouchDbDomainService';
import DateConvertingDomainService from './DateConvertingDomainService';
import PouchDbSettingsService from './PouchDbSettingsService';

export default function buildDomain(database) {
  const sync = new SyncManager(database);
  const _domainService = new PouchDbDomainService(database);
  const domainService = new DateConvertingDomainService(_domainService);
  const settingsService = new PouchDbSettingsService(database);

  return {
    sync,
    domainService,
    settingsService,
  };
}
