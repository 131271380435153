import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';

import BackButton from './BackButton'
import { Heading } from './Typography';

const TopBar = ({ showBack, icon, children }) => (
  <Toolbar disableGutters>
    { showBack && <BackButton /> }
    <Heading paragraph={false} style={{ flexGrow: 1 }}>{ children }</Heading>
    { icon }
  </Toolbar>
);

export default TopBar;
