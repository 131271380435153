import React from 'react';
import { flow } from 'lodash/fp';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import withLogMessages from '../components/withLogMessages';
import TopBar from '../components/TopBar';

const Log = ({ applicationLog=[] }) => (
  <React.Fragment>

    <TopBar>Log</TopBar>

    <List dense disablePadding>
    {
      applicationLog.map((item, i) => (
        <ListItem divider disableGutters key={i}>
          { item.ts.toISOString() }
          <br/> 
          {
            item.args
              .map(i => typeof(i) === 'string' ? i : JSON.stringify(i))
              .join(' ')
          }
        </ListItem>
      ))
    }
    </List>

  </React.Fragment>
);

export default flow([
  withLogMessages('applicationLog'),
])(Log);
