import React from 'react';

import Typography from '@material-ui/core/Typography';

const Heading = (props) =>
    <Typography
      variant='h5'
      paragraph={true}
      {...props}
    />;

const Paragraph = (props) =>
    <Typography
      variant='body1'
      paragraph={true}
      {...props}
    />;

const Span = (props) =>
    <Paragraph
      paragraph={false}
      {...props}
    />;

export {
  Heading,
  Paragraph,
  Span,
};
