import { getOr } from 'lodash/fp';

export const withSwitchChange = (handler) => (e, checked) =>
  handler(checked);

export const withDomChange = (handler) => (e) =>
  handler(e.target.value);

export const withFile = (handler) => (e) =>
  handler(getOr(null, 'target.files[0]', e));
