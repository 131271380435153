import Signal from 'signals';

class Logger {
  constructor() {
    this.messages = [];
    this.changed = new Signal();
  }

  getMessages() {
    return this.messages;
  }

  log(...args) {
    const entry = this.toEntry(...args);
    this.messages.push(entry);

    this.changed.dispatch(this.messages);
  }

  toEntry(...args) {
    return { ts: new Date(), args };
  }

  subscribe(fn) {
    const subscription = this.changed.add(fn);
    console.log('Logger.subscribed')
    return () => {
      subscription.detach();
      console.log('Logger.unsubscribed');
    }
  }
}

// Expose as a singleton
export default new Logger();
