import * as queries from './aggregates';

import * as fp from 'lodash/fp';

// Default config

const DayInMs = 1000 * 60 * 60 * 24;

const DaysToAverage = 10;

/**
 * @param {Report[]} reports
 * @param {Record[]} records
 * @param {Object} [opts.limits]
 */
export const buildReports = (reports) => (...args) =>
  reports.map(report => buildReport(report)(...args));

/**
 * @param {Report} report
 * @param {Record[]} records
 * @param {Object} [opts.limits]
 */
export const buildReport = (report) => (records, opts={}) => {
  const limits = opts.limits || buildDefaultLimits(new Date(), records);

  return {
    name: report.name,
    id: report.id,
    icon: report.icon,
    subsets: report.subsets.map(subset => ({
      name: subset.name,
      results: getReport(records, limits, report, subset),
    })),
  };
};

const buildDefaultLimits = (now, records) => {
  const todayUntil = now;
  const todaySince = new Date(todayUntil.getTime() - DayInMs);

  const averageUntil = todaySince;
  const averageSince = new Date(averageUntil.getTime() - (DayInMs * DaysToAverage));

  const earliestDate = findEarliestDate(records);
  const safeAverageSince = earliestDate && (averageSince < earliestDate) ?
    earliestDate > averageUntil ? averageUntil : earliestDate :
    averageSince;

  return {
    today:   { since: todaySince,       until: todayUntil },
    average: { since: safeAverageSince, until: averageUntil },
  };
};

const findEarliestDate = fp.flow([
  fp.map(record => record.data.start || record.data.end),
  fp.filter(Boolean),
  fp.sortBy(fp.identity),
  fp.first,
]);

const getReport = (records, limits, { name: reportName, config }, { name: subsetName, filter }) => {
  const { today, average } = limits;

  const filteredRecords = records.filter(filter);

  const ratioOfAverageToToday = (average.until - average.since) / (today.until - today.since);

  return fp.omitBy(fp.isUndefined, {
    latestRecord: queries.latestRecord(filteredRecords),
    latestTime: config.latestTime ?
      queries.latestTime(today)(filteredRecords) :
      undefined,
    totalCount: config.totalCount ? {
      today: queries.totalCount(today)(filteredRecords),
      average: queries.totalCount(average)(filteredRecords) / ratioOfAverageToToday,
    } : undefined,
    totalDuration: config.totalDuration ? {
      today: queries.totalDuration(today)(filteredRecords),
      average: queries.totalDuration(average)(filteredRecords) / ratioOfAverageToToday,
    } : undefined,
    averageDuration: config.averageDuration ? {
      today: queries.averageDuration(today)(filteredRecords),
      average: queries.averageDuration(average)(filteredRecords),
    } : undefined,
    totalVolume: config.totalVolume ? {
      today: queries.totalVolume(today)(filteredRecords),
      average: queries.totalVolume(average)(filteredRecords) / ratioOfAverageToToday,
    } : undefined,
    dailyVolumes: config.dailyVolumes ?
      queries.dailyVolumes({ until: today.until, since: average.since })(filteredRecords) :
      undefined,
  });
};
