import React, { Component } from 'react';
import { connect } from 'react-redux';
import PouchDB from 'pouchdb-browser';
import { flow, pick } from 'lodash/fp';

import domain from '../../domain';
import PouchDbDomainService from '../../domain/PouchDbDomainService';
import DateConvertingDomainService from '../../domain/DateConvertingDomainService';

import TopBar from '../components/TopBar';
import { Paragraph } from '../components/Typography';
import { TextButton } from '../components/Forms';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import withLoading from '../components/withLoading';

const { diagnostics: { getDatabase } } = domain;

const Diagnostics = ({ settings={}, selectedDatabaseId }) => (
  <React.Fragment>

    <TopBar>Diagnostics</TopBar>

    <LocalDbLoading selectedDatabaseId={selectedDatabaseId} />

    <Divider />

    <LocalDomainLoading selectedDatabaseId={selectedDatabaseId}  />

    <Divider />

    <LocalFullDomainLoading selectedDatabaseId={selectedDatabaseId}  />

    <Divider />

    <RemoteDbLoading syncUrl={settings.syncUrl} />

    <Divider />

    <FakeRenderError />

  </React.Fragment>
);

class TimedTask extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRunning: false,
      result: null,
      duration: null,
    };
  }

  track = (action) => {
    const start = performance.now()
    this.setState({
      isRunning: true,
      result: null,
      duration: null,
    })
    const onFinished = result =>
      this.setState({
        isRunning: false,
        result,
        duration: performance.now() - start,
      })
    action.then(onFinished, onFinished)
  };
}

class LocalDbLoading extends TimedTask {
  run = async () => {
    const database = await getDatabase(this.props.selectedDatabaseId);
    const action = database
      .allDocs({ include_docs: true })
      .then(result => result.rows.length)
    this.track(action);
  };

  render() {
     const { isRunning, result, duration } = this.state;

    return (
      <div>
        <TextButton disabled={isRunning} onClick={this.run}>
          Load all records direct from local DB (raw)
        </TextButton>
        { isRunning
          ? <CircularProgress size={20} />
          : result && <Paragraph>{ `${result} rows in ${Math.floor(duration)} ms` }</Paragraph>
        }
      </div>
    );
  }
}

class LocalDomainLoading extends TimedTask {
  run = async () => {
    const database = await getDatabase(this.props.selectedDatabaseId);
    const service = new PouchDbDomainService(database);
    const action = service
      .getRecords()
      .then(result => result.length)
    this.track(action);
  };

  render() {
    const { isRunning, result, duration } = this.state;

    return (
      <div>
        <TextButton disabled={isRunning} onClick={this.run}>
          Load all records from local domain (raw)
        </TextButton>
        <br />
        { isRunning
          ? <CircularProgress size={20} />
          : result && <Paragraph>{ `${result} rows in ${Math.floor(duration)} ms` }</Paragraph>
        }
      </div>
    );
  }
}

class LocalFullDomainLoading extends TimedTask {
  run = async () => {
    const database = await getDatabase(this.props.selectedDatabaseId);
    const _service = new PouchDbDomainService(database);
    const service = new DateConvertingDomainService(_service);
    const action = service
      .getRecords()
      .then(result => result.length)
    this.track(action);
  };

  render() {
    const { isRunning, result, duration } = this.state;

    return (
      <div>
        <TextButton disabled={isRunning} onClick={this.run}>
          Load all records from local domain (transformed)
        </TextButton>
        <br />
        { isRunning
          ? <CircularProgress size={20} />
          : result && <Paragraph>{ `${result} rows in ${Math.floor(duration)} ms` }</Paragraph>
        }
      </div>
    );
  }
}

class RemoteDbLoading extends TimedTask {
  run = () => {
    const database = new PouchDB(this.props.syncUrl, { skip_setup: true });
    const action = database
      .allDocs({ include_docs: true })
      .then(result => result.rows.length, error => `[${error.message}]`)
    this.track(action);
  };

  render() {
    const { syncUrl } = this.props;
    const { isRunning, result, duration } = this.state;

    return (
      <div>
        <TextButton disabled={isRunning || !syncUrl} onClick={this.run}>
          Load all records direct from remote DB (raw)
        </TextButton>
        <br />
        { isRunning
          ? <CircularProgress size={20} />
          : result && <Paragraph>{ `${result} rows in ${Math.floor(duration)} ms` }</Paragraph>
        }
      </div>
    );
  }
}

class FakeRenderError extends Component {
  constructor(props) {
    super(props)
    this.state = { clicked: false };
    this.triggerError = this.triggerError.bind(this)
  }

  triggerError() {
    this.setState({ clicked: true })
  }

  render() {
    const { clicked } = this.state;
    return (
      <div>
        <TextButton onClick={this.triggerError}>
          Fake a rendering error
        </TextButton>
        <br />
        { clicked && <FakeRenderError.RenderError /> }
      </div>
    );
  }
}
FakeRenderError.RenderError = () => {
  throw new Error("Oh no!  Fake error!")
}

export default flow([
  withLoading(props => !props.settings),
  connect(pick(['selectedDatabaseId', 'settings'])),
])(Diagnostics);
