import React, { Component } from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';

import { restartSync } from '../data/redux/thunks';

/**
 * A simple component restarts DB sync.
 */
class DatabaseSyncTrigger extends Component {

  render() {
    const { restartSync, ...rest } = this.props;

    return (
      <div onClick={ restartSync } {...rest} />
    );
  }

};

export default flow([
  connect(null, { restartSync }),
])(DatabaseSyncTrigger);
