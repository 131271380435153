import PouchDB from 'pouchdb-browser';
import Signal from 'signals';

const Status = {
  disconnected: 'disconnected',
  connecting: 'connecting',
  connected: 'connected',
  active: 'active',
};

class SyncManager {

  constructor(db) {
    this.db = db;

    // DB sync
    this.syncInstance = null;
    this.remoteDb = null;

    // Status
    this.status = Status.disconnected;
    this.error = null;

    // Signals
    this.signals = {
      changed: new Signal(),
      internal: new Signal(),
    };
  }

  // Interface

  get on() {
    return this.signals;
  }

  /** Unsubscribe a subscription obtained from `this.on.foo.add(fn)` */
  unsubscribe(subscription) {
    // HACK: We know the subscriptions are of type SignalContext
    subscription.detach();
  }

  stop() {
    if (this.syncInstance) {
      this.syncInstance.cancel();
      this.syncInstance = null;
    }

    if (this.remoteDb) {
      this.remoteDb.close();
      this.remoteDb = null;
    }

    this.setStatus({ status: Status.disconnected });
  }

  start(remoteCouch) {
    const onError = (err) => {
      this.setStatus({ error: err });

      this.stop();
    };

    const onActive = () => {
      this.setStatus({ status: Status.active });
    };

    const onPaused = () => {
      this.setStatus({ status: Status.connected });
    };

    const onInternalEvent = (...typeArgs) => (...eventArgs) => {
      this.signals.internal.dispatch(...typeArgs, ...eventArgs);
    };

    this.stop();

    this.setStatus({ status: Status.connecting, error: null });

    if (!remoteCouch) {
      this.stop();
      return;
    }

    if (!isValidRemoteUrl(remoteCouch)) {
      onError({ message: 'Invalid sync URL' });
      return;
    }

    this.remoteDb = new PouchDB(remoteCouch, { skip_setup: true });
    this.remoteDb.info()
      .then(() => {
        this.syncInstance = this.db.sync(this.remoteDb, { live: true, batch_size: 750 });

        this.syncInstance.catch(onError);

        this.syncInstance
          .on('error', onError)
          .on('active', onActive)
          .on('change', onInternalEvent('change'))
          .on('paused', onPaused)
          .on('denied', onInternalEvent('denied'))
          .on('complete', onInternalEvent('complete'));

        this.setStatus({ status: Status.connected });
      })
      .catch(_err => {
        // Raise a more descriptive error
        onError('Could not connect to server');
      });
  }

  setStatus({ status, error }={}) {
    const before = this.getStatus();

    this.status = ifDefined(status, this.status);
    this.error  = ifDefined(error, this.error);

    const after = this.getStatus();

    if (!areEqual(before, after)) {
      this.signals.changed.dispatch(after);
    }
  }

  getStatus() {
    return {
      status: this.status,
      error: this.error,
    };
  }

}

const ifDefined = (value, fallback) =>
  value !== undefined ? value : fallback;

const areEqual = (before, after) => [
  before.status === after.status,
  before.error === after.error,
].every(Boolean);

const isValidRemoteUrl = (remoteCouch) =>
  /^https?:\/\/.+/.test(remoteCouch);

export default SyncManager
